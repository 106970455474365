#tooltip-container {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    align-items: center;
    pointer-events: none;

    opacity: 0;

    transition: all ease-in-out 0.2s;

    cursor: default;
    overflow-y: scroll;
    img {
        max-width: 600px;
        width: 100%;
        height: auto;
        max-height: 80vh;
        object-fit: contain;
        padding-left: 20%;
    }
}

.tooltip-exit {
    transform: rotate(45deg);
    position: absolute;
    right: 34px;
    top: 34px;
    cursor: pointer;
}

.column-left,
.column-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}

#tooltip-img {
    max-width: 700px;
}
#tooltip-title {
    font-size: 24px;
}

#link-container {
    display: none;
}

.tooltipContent {
    padding: 0 16px;
    gap: 16px;
    /* padding-top: 10vh; */
    width: 100%;
    display: flex;
    /* width: fit-content; */
    /* align-items: center; */
    flex-direction: row;
    margin: 0 auto;
    height: 100%;
}

.tooltipText {
    max-width: 400px;
}

@media screen and (max-width: 768px) {
    .column-left,
    .column-right {
        width: 100%;
        justify-content: start;
    }

    .tooltipText {
        max-width: 100%;
    }

    #tooltip-container {
        width: 100%;
        img {
            margin-bottom: 4vh;
            max-width: 100%;
            max-height: 400px;
            object-fit: contain;
            padding-left: 0;
        }
    }

    #tooltip-img {
        max-width: "";
    }
    .tooltipContent {
        flex-direction: column;
        margin-top: 10vh;
        width: inherit;
        gap: 0;
        margin-bottom: 20vh;
        width: auto;
    }
}
