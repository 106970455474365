@media print,
screen and (min-width:48em) {
    .donation-protection-modal_modal__bKXeC .m-base-modal-inner {
        padding-left: 4.5rem;
        padding-right: 4.5rem;
        width: 35rem
    }
}

.donation-protection-modal_headline__IoOGL {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center
}

@media print,
screen and (min-width:48em) {
    .donation-protection-modal_headline__IoOGL {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }
}

.donation-protection-modal_listItem__N6PJn {
    display: flex;
    padding: .5rem 0
}

@media print,
screen and (min-width:48em) {
    .donation-protection-modal_listItem__N6PJn {
        padding: .5rem 1.5rem
    }
}

.donation-protection-modal_hr__6riqc {
    border-top: 1px solid #e4e4e4;
    padding: .5rem 0
}

/*# sourceMappingURL=3e42d1ecba845521.css.map*/