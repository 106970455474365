.hero_hide-x-overflow__9DwjM {
    overflow-x: hidden
}

.hero_homepageSection__B8bG7 {
    margin-bottom: 3.5rem
}

@media print,
screen and (min-width:64em) {
    .hero_homepageSection__B8bG7 {
        margin-bottom: 6.5rem
    }
}

.hero_homepageSectionExperiment__W3VwU {
    margin-bottom: 3.75rem
}

@media print,
screen and (min-width:64em) {
    .hero_homepageSectionExperiment__W3VwU {
        margin-bottom: 6rem
    }
}

.hero_heading__sECed {
    margin: 0 auto
}

.hero_subheading__6Z4fn {
    margin-top: .5rem
}

.hero_grid-columns__3qzPw {
    grid-gap: 2rem
}

.hero_heading-1__iROK8 {
    font-size: 2.5rem;
    line-height: 1.1
}

@keyframes hero_fadeIn__OYUcF {
    0% {
        opacity: 0;
        transform: translate3d(0, .5rem, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

@keyframes hero_fadeInPartial__TfKfy {
    0% {
        opacity: 0
    }
    to {
        opacity: .6
    }
}

@keyframes hero_fadeOutPartial__owHkd {
    0% {
        opacity: .6
    }
    to {
        opacity: 0
    }
}

.hero_filter__lQdK_ {
    background: #333;
    height: 100%;
    opacity: 0;
    width: 100%;
    z-index: 1
}

.hero_filterIn__k6EW6 {
    animation: hero_fadeInPartial__TfKfy .4s linear .4s forwards
}

.hero_filterOut__bw748 {
    animation: hero_fadeOutPartial__owHkd .2s linear
}

.hero_hero__3_xYh {
    height: 37.875rem;
    margin-bottom: 0 !important;
    padding-top: 3.75rem;
    position: sticky;
    top: 0;
    width: 100vw;
    z-index: 0
}

@media screen and (max-width:47.99875em) {
    .hero_hero__3_xYh {
        height: 32.1875rem
    }
}

.hero_heroContainer__UgXOw {
    background-image: url(https://d25oniaj7o2jcw.cloudfront.net/illustration-mobile-20220610@1x.jpg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0;
    filter: brightness(100%);
    height: 100vh;
    margin: 0;
    overflow-y: visible;
    padding: 0;
    position: fixed;
    top: 0;
    transform: scale(100%);
    width: 100vw;
    z-index: 0
}

@media screen and (max-width:41.68625em) {
    .hero_heroContainer__UgXOw {
        background-position: 0 -1.3125rem;
        max-height: unset
    }
}

@media screen and (min-width:23.5em) {
    .hero_heroContainer__UgXOw {
        background-position: 0 -2rem;
        max-height: 39.375rem
    }
}

@media screen and (min-width:48em) {
    .hero_heroContainer__UgXOw {
        background-image: url(https://d25oniaj7o2jcw.cloudfront.net/full-illustration-tablet-20220802@1x.jpg);
        background-position: 50% 50%;
        max-height: 39.375rem
    }
}

@media print,
screen and (min-width:64em) {
    .hero_heroContainer__UgXOw {
        background-image: url(https://d25oniaj7o2jcw.cloudfront.net/full-illustration-desktop-20220802@1x.jpg)
    }
}

@media screen and (min-width:72em) {
    .hero_heroContainer__UgXOw {
        background-image: url(https://d25oniaj7o2jcw.cloudfront.net/full-illustration-desktop-20220802@2x.jpg)
    }
}

.hero_heroContent__MVREJ {
    margin: 0;
    max-width: 100%;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    position: fixed;
    text-align: center;
    top: 4.25rem;
    width: 100%;
    left: 50%;
    top: 13.75rem;
    transform: translate(-50%, -50%)
}

@media print,
screen and (min-width:48em) {
    .hero_heroContent__MVREJ {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
    }
}

.hero_heroContent__MVREJ>* {
    opacity: 0
}

.hero_text__D3YI2 {
    animation: hero_fadeIn__OYUcF .4s linear .4s forwards;
    color: #18412d;
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 1.1;
    margin: 0 auto;
    max-width: 17.1875rem;
    text-align: center
}

@media screen and (max-width:41.68625em) {
    .hero_text__D3YI2 {
        font-size: 2.25rem;
        line-height: 2.375rem
    }
}

.hero_textSmall__AbiKL {
    font-family: CircularXXWeb, Trebuchet, Helvetica, Arial, sans-serif;
    font-size: clamp(1rem, 1rem + .25*(100vw - 23.4375rem)/66.5625, 1.25rem);
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    max-width: 18.75rem
}

@media screen and (max-width:41.68625em) {
    .hero_textSmall__AbiKL {
        margin-bottom: 1rem
    }
}

.hero_button__xFp33 {
    animation: hero_fadeIn__OYUcF .2s linear .6s forwards;
    background-color: #fff;
    color: #008748;
    margin-top: 2rem
}

@media screen and (min-width:41.6875em) {
    .hero_button__xFp33 {
        border: none;
        margin-top: 3rem;
        min-height: 3.5rem;
        min-width: 13.5rem;
        width: -moz-fit-content;
        width: fit-content
    }
}

.hero_button__xFp33:hover {
    background-color: #e6f6ef;
    color: #008748;
    text-decoration: none
}

@media print,
screen and (min-width:48em) {
    .hero_hero__3_xYh {
        padding-top: 0
    }
    .hero_heroContainer__UgXOw {
        border-radius: 0;
        margin: 0
    }
}

@media only screen and (-webkit-min-device-pixel-ratio:2),
only screen and (min-resolution:2dppx) {
    .hero_heroContainer__UgXOw {
        background-image: url(https://d25oniaj7o2jcw.cloudfront.net/illustration-mobile-20220610@2x.jpg)
    }
}

@media only screen and (-webkit-min-device-pixel-ratio:2)and (min-width:48em),
only screen and (min-resolution:2dppx)and (min-width:48em) {
    .hero_heroContainer__UgXOw {
        background-image: url(https://d25oniaj7o2jcw.cloudfront.net/full-illustration-tablet-20220802@2x.jpg)
    }
}

@media only screen and (-webkit-min-device-pixel-ratio:2)and (min-width:64em),
only screen and (min-resolution:2dppx)and (min-width:64em) {
    .hero_heroContainer__UgXOw {
        background-image: url(https://d25oniaj7o2jcw.cloudfront.net/full-illustration-desktop-20220802@2x.jpg)
    }
}

@media only screen and (-webkit-min-device-pixel-ratio:2)and (min-width:64em)and (orientation:portrait),
only screen and (min-resolution:2dppx)and (min-width:64em)and (orientation:portrait) {
    .hero_heroContainer__UgXOw {
        min-height: 37.875rem
    }
}

@media only screen and (-webkit-min-device-pixel-ratio:3),
only screen and (min-resolution:3dppx) {
    .hero_heroContainer__UgXOw {
        background-image: url(https://d25oniaj7o2jcw.cloudfront.net/illustration-mobile-20220610@3x.jpg)
    }
}

@media only screen and (-webkit-min-device-pixel-ratio:3)and (min-width:48em),
only screen and (min-resolution:3dppx)and (min-width:48em) {
    .hero_heroContainer__UgXOw {
        background-image: url(https://d25oniaj7o2jcw.cloudfront.net/full-illustration-tablet-20220802@3x.jpg)
    }
}

@media only screen and (-webkit-min-device-pixel-ratio:3)and (min-width:64em),
only screen and (min-resolution:3dppx)and (min-width:64em) {
    .hero_heroContainer__UgXOw {
        background-image: url(https://d25oniaj7o2jcw.cloudfront.net/full-illustration-desktop-20220802@3x.jpg)
    }
}

@media only screen and (-webkit-min-device-pixel-ratio:3)and (min-width:64em)and (orientation:portrait),
only screen and (min-resolution:3dppx)and (min-width:64em)and (orientation:portrait) {
    .hero_heroContainer__UgXOw {
        min-height: 37.875rem
    }
}

.hero-italy_progress-dots__4CA4o {
    display: flex;
    flex-flow: row wrap;
    gap: .375rem;
    justify-content: flex-start;
    position: relative
}

.hero-italy_progress-dots__4CA4o:after,
.hero-italy_progress-dots__4CA4o:before {
    background-color: #333;
    border-radius: .1875rem;
    content: "";
    display: inline-flex;
    height: .375rem;
    margin-left: 0;
    position: absolute;
    width: 1.125rem;
    z-index: 2
}

@media(prefers-reduced-motion:no-preference) {
    .hero-italy_progress-dots__4CA4o:after {
        transition: margin-left .1s ease-out
    }
    .hero-italy_progress-dots__4CA4o:before {
        transition: margin-left .1s ease-out .2s
    }
}

.hero-italy_progress-dots__4CA4o[data-position="0"]:after,
.hero-italy_progress-dots__4CA4o[data-position="0"]:before {
    margin-left: 0
}

.hero-italy_progress-dots__4CA4o[data-position="1"]:after,
.hero-italy_progress-dots__4CA4o[data-position="1"]:before {
    margin-left: .75rem
}

.hero-italy_progress-dots__4CA4o[data-position="2"]:after,
.hero-italy_progress-dots__4CA4o[data-position="2"]:before {
    margin-left: 1.5rem
}

.hero-italy_progress-dots__4CA4o[data-position="3"]:after,
.hero-italy_progress-dots__4CA4o[data-position="3"]:before {
    margin-left: 2.25rem
}

.hero-italy_progress-dots-item__11JXe {
    background-color: #3333331a;
    border-radius: .1875rem;
    display: inline-flex;
    height: .375rem;
    transition: margin-left .3s ease-in-out;
    width: .375rem;
    z-index: 1
}

.hero-italy_hide-x-overflow__0tCRH {
    overflow-x: hidden
}

.hero-italy_homepageSection__bsbKl {
    margin-bottom: 3.5rem
}

@media print,
screen and (min-width:64em) {
    .hero-italy_homepageSection__bsbKl {
        margin-bottom: 6.5rem
    }
}

.hero-italy_homepageSectionExperiment__kOVSs {
    margin-bottom: 3.75rem
}

@media print,
screen and (min-width:64em) {
    .hero-italy_homepageSectionExperiment__kOVSs {
        margin-bottom: 6rem
    }
}

.hero-italy_heading__7Vx3p {
    margin: 0 auto
}

.hero-italy_subheading__ESkF1 {
    margin-top: .5rem
}

.hero-italy_grid-columns__xmRWb {
    grid-gap: 2rem
}

.hero-italy_heading-1__M4VeQ {
    font-size: 2.5rem;
    line-height: 1.1
}

.hero-italy_homepage-section-italy___MpWV {
    margin-bottom: 1.5rem
}

@media print,
screen and (min-width:64em) {
    .hero-italy_homepage-section-italy___MpWV {
        margin-bottom: 14rem
    }
}

.hero-italy_hero-wrapper--skeleton__DObLY {
    background: #0000
}

@media screen and (max-width:47.99875em) {
    .hero-italy_hero-wrapper--skeleton__DObLY {
        background-color: #f8f8f8
    }
}

.hero-italy_hero-background__UCf6Q {
    background-color: #f8f8f8;
    height: 72vh;
    left: 0;
    max-height: 34.25rem;
    min-height: 28.25rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1
}

@media print,
screen and (min-width:64em) {
    .hero-italy_hero-background__UCf6Q {
        max-height: 43.875rem
    }
}

.hero-italy_hero-background-italy__ce9c0 {
    max-height: 36rem
}

@media print,
screen and (min-width:64em) {
    .hero-italy_hero-background-italy__ce9c0 {
        max-height: 51.25rem;
        min-height: 51.25rem
    }
}

.hero-italy_hero__riEhe {
    color: #fff;
    min-height: 43.25rem;
    padding-bottom: .5rem;
    position: relative
}

@media screen and (max-width:63.99875em) {
    .hero-italy_hero__riEhe {
        margin-left: auto;
        margin-right: auto;
        max-width: 48rem;
        padding-left: 1rem;
        padding-right: 1rem
    }
}

@media print,
screen and (min-width:48em) {
    .hero-italy_hero__riEhe {
        height: 82vh;
        max-height: 37.625rem;
        min-height: 31.625rem
    }
}

@media print,
screen and (min-width:64em) {
    .hero-italy_hero__riEhe {
        max-height: 47.25rem;
        padding-bottom: 2rem;
        padding-top: 5.5rem
    }
}

.hero-italy_hero-inner__TnKgE {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 100%
}

@media print,
screen and (min-width:64em) {
    .hero-italy_hero-inner__TnKgE {
        margin-left: auto;
        margin-right: auto;
        max-width: 60rem
    }
}

@media screen and (min-width:72em) {
    .hero-italy_hero-inner__TnKgE {
        max-width: 72rem
    }
}

.hero-italy_hero-images--skeleton__epTZr,
.hero-italy_hero-inner--skeleton__Yfj5H {
    opacity: 0
}

.hero-italy_hero-content__eXQW8 {
    margin-top: 6rem
}

@media screen and (max-width:47.99875em) {
    .hero-italy_hero-content__eXQW8 {
        margin-left: auto;
        margin-right: auto
    }
}

@media print,
screen and (min-width:48em) {
    .hero-italy_hero-content__eXQW8 {
        display: flex;
        flex-direction: column;
        margin-bottom: auto;
        margin-top: auto
    }
    .hero-italy_hero-content-italy__DCK0c {
        margin-top: 6.25rem
    }
}

.hero-italy_hero-scroll-trigger__pfV7z {
    margin-bottom: -1rem;
    margin-top: 1rem
}

.hero-italy_hero-content-start-button__9uPWt {
    border: 0;
    border-radius: 1.75rem;
    font-size: 1.125rem;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 3.5rem
}

@media print,
screen and (min-width:48em) {
    .hero-italy_hero-content-start-button__9uPWt {
        margin-top: 2.5rem;
        min-width: 14.375rem;
        width: -moz-fit-content;
        width: fit-content
    }
}

@media screen and (max-width:47.99875em) {
    .hero-italy_hero-content-start-button__9uPWt {
        margin-top: 2.5rem;
        width: 100%
    }
}

@media screen and (min-width:48em)and (max-width:63.99875em) {
    .hero-italy_hero-content-start-button-italy__nYaY_ {
        margin: 2.5rem auto auto
    }
}

.hero-italy_hero-content-start-button-icon__WVrgu {
    height: auto;
    margin-right: .5rem;
    width: 1.375rem
}

.hero-italy_hero-text__1CvdO {
    font-size: 2.5rem;
    font-weight: 900;
    line-height: 1.1
}

@media screen and (min-width:48em) {
    .hero-italy_hero-text__1CvdO {
        display: flex;
        flex-direction: column
    }
}

@media screen and (min-width:60em)and (min-height:48em) {
    .hero-italy_hero-text__1CvdO {
        font-size: 3.75rem
    }
}

@media screen and (max-width:47.99875em) {
    .hero-italy_hero-text__1CvdO {
        justify-content: center;
        text-align: center
    }
}

@media screen and (min-width:48em)and (max-width:63.99875em) {
    .hero-italy_hero-text-italy__U6qEE {
        align-items: center
    }
}

.hero-italy_hero-copy-italy__kik1N {
    color: #333;
    font-size: 1.48rem;
    margin-top: 2.5rem
}

@media screen and (min-width:64em) {
    .hero-italy_hero-copy-italy__kik1N {
        width: 28.625rem
    }
}

@media screen and (max-width:47.99875em) {
    .hero-italy_hero-copy-italy__kik1N {
        font-size: 1.375rem
    }
}

.hero-italy_hero-footer__urFAF {
    bottom: 1rem;
    display: flex;
    left: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;
    right: 0
}

@media print,
screen and (min-width:64em) {
    .hero-italy_hero-footer__urFAF {
        align-items: center;
        bottom: 1.5rem;
        justify-content: space-between;
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }
}

.hero-italy_hero-meta__y2uTE {
    font-weight: 900;
    height: 2.625em;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    width: 100%
}

.hero-italy_hero-meta-text__E9nsd {
    bottom: 0;
    line-height: 1;
    opacity: 0;
    position: absolute;
    top: 0
}

@media(prefers-reduced-motion:no-preference) {
    .hero-italy_hero-meta-text__E9nsd {
        transition: opacity .5s ease-in-out .1s
    }
}

.hero-italy_hero-progress-dots__aLHLo {
    flex-shrink: 0
}

@media screen and (max-width:63.99875em) {
    .hero-italy_hero-progress-dots__aLHLo {
        margin-bottom: auto;
        margin-top: auto
    }
}

.hero-italy_hero-underline__qEasr {
    display: inline-block;
    margin-top: .25rem;
    pointer-events: none
}

@media(prefers-reduced-motion:no-preference) {
    .hero-italy_hero-underline__qEasr {
        transition: opacity .2s ease-out
    }
    .hero-italy_hero-underline-path__J7KFY {
        transition: stroke-dasharray .5s cubic-bezier(.4, 0, .2, 1) .5s, stroke-dashoffset .5s cubic-bezier(.4, 0, .2, 1) .5s
    }
}

.hero-italy_hero-scroller__MzcX_ {
    height: 1.6em;
    overflow: hidden
}

@media screen and (min-width:60em)and (min-height:48em) {
    .hero-italy_hero-scroller__MzcX_ {
        height: 1.5em
    }
}

.hero-italy_hero-scroller-item__kou7v {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0
}

@media(prefers-reduced-motion:no-preference) {
    .hero-italy_hero-scroller-item__kou7v {
        transition: transform .5s cubic-bezier(1, -.4, .4, .6), opacity .3s ease .3s
    }
}

@media screen and (max-width:47.99875em) {
    .hero-italy_hero-scroller-item__kou7v {
        align-items: center
    }
}

@media screen and (min-width:48em)and (max-width:63.99875em) {
    .hero-italy_hero-scroller-item-italy___erTu {
        align-items: center
    }
}

.hero-italy_hero-images__GQSYB {
    background-color: #767676;
    border-radius: 1.25rem;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 21.625rem;
    z-index: -1
}

@media screen and (min-width:48em) {
    .hero-italy_hero-images__GQSYB {
        left: 49%;
        top: 7.3125rem
    }
}

@media print,
screen and (min-width:64em) {
    .hero-italy_hero-images__GQSYB {
        top: 9rem
    }
}

@media screen and (min-width:72em) {
    .hero-italy_hero-images__GQSYB {
        left: 43%
    }
}

.hero-italy_hero-images-italy__vpmA4 {
    height: 45.6875rem
}

@media screen and (max-width:63.99875em) {
    .hero-italy_hero-images-hide-on-mobile__ckCtR {
        display: none
    }
}

.hero-italy_hero-picture__k5nrY {
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%
}

@media(prefers-reduced-motion:no-preference) {
    .hero-italy_hero-picture__k5nrY {
        transition: opacity .5s ease-in-out
    }
}

.hero-italy_hero-image__leJjs {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.hero-italy_hero__riEhe[data-item="0"] .hero-italy_hero-scroller-item__kou7v {
    transform: translateY(100%)
}

.hero-italy_hero__riEhe[data-item="1"] .hero-italy_hero-scroller-item__kou7v {
    transform: translateY(0)
}

.hero-italy_hero__riEhe[data-item="2"] .hero-italy_hero-scroller-item__kou7v {
    transform: translateY(-100%)
}

.hero-italy_hero__riEhe[data-item="3"] .hero-italy_hero-scroller-item__kou7v {
    transform: translateY(-200%)
}

.hero-italy_hero__riEhe[data-item="4"] .hero-italy_hero-scroller-item__kou7v {
    transform: translateY(-300%)
}

.hero-italy_hero__riEhe[data-item="0"] .hero-italy_hero-picture__k5nrY:first-child,
.hero-italy_hero__riEhe[data-item="1"] .hero-italy_hero-meta-text__E9nsd:first-child,
.hero-italy_hero__riEhe[data-item="1"] .hero-italy_hero-picture__k5nrY:first-child,
.hero-italy_hero__riEhe[data-item="1"] .hero-italy_hero-scroller-item__kou7v:first-child,
.hero-italy_hero__riEhe[data-item="2"] .hero-italy_hero-meta-text__E9nsd:nth-child(2),
.hero-italy_hero__riEhe[data-item="2"] .hero-italy_hero-picture__k5nrY:nth-child(2),
.hero-italy_hero__riEhe[data-item="2"] .hero-italy_hero-scroller-item__kou7v:nth-child(2) {
    opacity: 1
}

.hero-italy_hero__riEhe[data-item="2"] .hero-italy_hero-scroller-item__kou7v:first-child .hero-italy_hero-underline__qEasr {
    opacity: 0
}

.hero-italy_hero__riEhe[data-item="3"] .hero-italy_hero-meta-text__E9nsd:nth-child(3),
.hero-italy_hero__riEhe[data-item="3"] .hero-italy_hero-picture__k5nrY:nth-child(3),
.hero-italy_hero__riEhe[data-item="3"] .hero-italy_hero-scroller-item__kou7v:nth-child(3) {
    opacity: 1
}

.hero-italy_hero__riEhe[data-item="3"] .hero-italy_hero-scroller-item__kou7v:nth-child(2) .hero-italy_hero-underline__qEasr {
    opacity: 0
}

.hero-italy_hero__riEhe[data-item="4"] .hero-italy_hero-meta-text__E9nsd:nth-child(4),
.hero-italy_hero__riEhe[data-item="4"] .hero-italy_hero-picture__k5nrY:nth-child(4),
.hero-italy_hero__riEhe[data-item="4"] .hero-italy_hero-scroller-item__kou7v:nth-child(4) {
    opacity: 1
}

.hero-italy_hero__riEhe[data-item="4"] .hero-italy_hero-scroller-item__kou7v:nth-child(3) .hero-italy_hero-underline__qEasr {
    opacity: 0
}

.about-gfm_background__KWuIP {
    background-color: #f0fce9;
    border-radius: 1.5rem;
    display: flex;
    grid-column: 1;
    grid-row: 1;
    height: 46.125rem;
    margin: 2.5rem auto 6.75rem;
    padding: 4.1875rem 1rem 5.3125rem;
    width: 90%
}

@media(prefers-reduced-motion:no-preference) {
    .about-gfm_background__KWuIP {
        transition: border-radius .4s, width .2s
    }
}

.about-gfm_backgroundInView__Ibh9t {
    border-radius: 0;
    width: 100%
}

@media print,
screen and (min-width:64em) {
    .about-gfm_background__KWuIP {
        height: 47.625rem;
        margin-bottom: 10.5rem;
        margin-top: 4.5rem;
        padding: 12.1875rem 0
    }
}

.about-gfm_heading__Efo2L {
    font-weight: 500;
    max-width: 46.875rem
}

.about-gfm_content__pL7xR {
    grid-column: 1;
    grid-row: 1;
    margin: auto;
    opacity: 0;
    padding: .0625rem 2rem 5.3125rem;
    transform: translateY(20%)
}

@media print,
screen and (min-width:64em) {
    .about-gfm_content__pL7xR {
        padding: 0 1rem 5.3125rem;
        transform: translateY(50%);
        width: 72rem
    }
}

@media(prefers-reduced-motion:no-preference) {
    .about-gfm_content__pL7xR {
        transition: transform .5s, opacity .5s
    }
}

.about-gfm_contentInView__aQUQE {
    opacity: 100%;
    transform: translateY(0)
}

.about-gfm_innerContent__RL6Ey {
    max-width: 60rem
}

.fundraise-accordion_wrapper__CetRl {
    padding-bottom: 6.75rem
}

@media print,
screen and (min-width:64em) {
    .fundraise-accordion_wrapper__CetRl {
        padding-bottom: 10.5rem
    }
}

.fundraise-accordion_h3__eu5HV {
    font-size: 2.5rem;
    font-weight: "500 600";
    line-height: 2.5rem;
    margin-bottom: 0
}

@media screen and (max-width:47.99875em) {
    .fundraise-accordion_h3__eu5HV {
        font-size: 1.75rem;
        font-weight: 500
    }
    .fundraise-accordion_icon__9FH44 {
        transform: scale(.75)
    }
}

.fundraise-accordion_icon__9FH44 path {
    transition: stroke .2s ease
}

.fundraise-accordion_icon__9FH44 rect {
    transition: opacity .2s ease
}

.fundraise-accordion_icon__9FH44 .fundraise-accordion_svgOff__1T6pz {
    opacity: 1
}

.fundraise-accordion_icon__9FH44 .fundraise-accordion_svgOn__OreeN {
    opacity: 0
}

.fundraise-accordion_item__QGdqP {
    border-bottom: .0625rem solid #3333;
    cursor: pointer;
    overflow: hidden;
    padding: 3rem 0 2rem;
    transition: max-height .5s;
    width: 100%
}

.fundraise-accordion_item__QGdqP p {
    color: #767676;
    font-size: 1.25rem;
    max-width: 47rem;
    opacity: 1;
    padding-top: 1.5rem;
    transition: opacity .5s
}

.fundraise-accordion_item__QGdqP:hover .fundraise-accordion_icon__9FH44 path {
    stroke: #333
}

.fundraise-accordion_item__QGdqP:hover .fundraise-accordion_icon__9FH44 .fundraise-accordion_svgOff__1T6pz {
    opacity: 0
}

.fundraise-accordion_item__QGdqP:hover .fundraise-accordion_icon__9FH44 .fundraise-accordion_svgOn__OreeN {
    opacity: 1
}

.merch-card_hide-x-overflow__pXqsn {
    overflow-x: hidden
}

.merch-card_homepageSection__30sLl {
    margin-bottom: 3.5rem
}

@media print,
screen and (min-width:64em) {
    .merch-card_homepageSection__30sLl {
        margin-bottom: 6.5rem
    }
}

.merch-card_homepageSectionExperiment__kPC_j {
    margin-bottom: 3.75rem
}

@media print,
screen and (min-width:64em) {
    .merch-card_homepageSectionExperiment__kPC_j {
        margin-bottom: 6rem
    }
}

.merch-card_heading__9VCEV {
    margin: 0 auto
}

.merch-card_subheading__m3bcQ {
    margin-top: .5rem
}

.merch-card_grid-columns__THFWO {
    grid-gap: 2rem
}

.merch-card_heading-1__lk6WL {
    font-size: 2.5rem;
    line-height: 1.1
}

.merch-card_carousel__L8hNP {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none
}

.merch-card_carousel__L8hNP::-webkit-scrollbar {
    display: none
}

.merch-card_carousel-item__Fr_fC {
    scroll-snap-align: start;
    scroll-snap-stop: always
}

.merch-card_merch-unit-title__N6ksd {
    margin-bottom: 0
}

.merch-card_merch-unit__936GP {
    border-radius: 1rem
}

@media screen and (max-width:47.99875em) {
    .merch-card_merch-unit__936GP {
        flex: 0 0 100%
    }
}

.merch-card_merch-unit-link__U4Zki {
    background-color: #f8f8f8;
    border-radius: 1rem;
    height: 100%;
    width: 100%
}

.merch-card_merch-unit-link--inline__8HSBK {
    display: grid;
    grid-template-columns: 1fr 2fr
}

.merch-card_merch-unit-link--stacked__EVGjO {
    display: block
}

.merch-card_merch-unit-image__S5lyz {
    background-position: 50%;
    background-size: cover;
    border-radius: 1rem 1rem 0 0 !important;
    overflow: hidden;
    padding-top: 12.875rem;
    position: relative
}

.merch-card_merch-unit-tag__tAN2z {
    font-weight: unset;
    left: 1rem;
    position: absolute;
    top: 1rem
}

.merch-card_merch-unit-content__OtFU9 {
    min-height: 9.5rem;
    padding: 1rem
}

@media screen and (max-width:47.99875em) {
    .merch-card_merch-unit-content__OtFU9 {
        padding: 1.5rem
    }
}

.merch-card_merch-unit-button__ZOIy0 {
    border-radius: .5rem;
    font-size: .875rem;
    font-weight: 800;
    line-height: 1;
    margin-right: auto;
    margin-top: auto;
    padding: .75rem 1rem .75rem 0;
    transition: .3s ease-in-out
}

.merch-card_merch-unit-button__ZOIy0>svg {
    height: .75rem;
    margin-left: .375rem;
    width: .75rem
}

@media print and (prefers-reduced-motion:no-preference),
screen and (min-width:48em)and (prefers-reduced-motion:no-preference) {
    .merch-card_merch-unit__936GP:hover .merch-card_merch-unit-button__ZOIy0 {
        background-color: #fff;
        padding-left: 1rem
    }
}

@media print,
screen and (min-width:48em) {
    .merch-card_merch-unit--1-col__O4C34 .merch-card_merch-unit-title__N6ksd {
        padding-bottom: .5rem
    }
    .merch-card_merch-unit--1-col__O4C34 .merch-card_merch-unit-link__U4Zki {
        background-color: #f8f8f8;
        border-radius: 1rem;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        min-height: 19.0625rem
    }
    .merch-card_merch-unit--1-col__O4C34 .merch-card_merch-unit-image__S5lyz {
        border-radius: 1rem 0 0 1rem !important
    }
    .merch-card_merch-unit--1-col__O4C34 .merch-card_merch-unit-content__OtFU9 {
        padding: 4rem
    }
    .merch-card_merch-unit--1-col__O4C34 .merch-card_merch-unit-description__iic_N {
        margin-bottom: 0
    }
    .merch-card_merch-unit--1-col__O4C34 .merch-card_merch-unit-button__ZOIy0 {
        margin-top: auto
    }
    .merch-card_merch-unit--2-col__H3iQS .merch-card_merch-unit-title__N6ksd {
        padding-bottom: .5rem
    }
    .merch-card_merch-unit--2-col__H3iQS .merch-card_merch-unit-link__U4Zki {
        display: inline-grid;
        min-height: 27.0625rem
    }
    .merch-card_merch-unit--2-col__H3iQS .merch-card_merch-unit-image__S5lyz {
        padding-top: 14.4375rem
    }
    .merch-card_merch-unit--2-col__H3iQS .merch-card_merch-unit-content__OtFU9 {
        min-height: 12.625rem;
        padding: 2.0625rem 1.5rem 1.5625rem
    }
    .merch-card_merch-unit--2-col__H3iQS .merch-card_merch-unit-description__iic_N {
        font-size: .875rem;
        line-height: 1.5;
        max-height: 6rem;
        overflow: hidden
    }
    .merch-card_merch-unit--3-col__X7b2p .merch-card_merch-unit-title__N6ksd {
        min-height: 3.5rem
    }
    .merch-card_merch-unit--3-col__X7b2p .merch-card_merch-unit-link__U4Zki {
        display: inline-grid;
        min-height: 23.5rem
    }
    .merch-card_merch-unit--3-col__X7b2p .merch-card_merch-unit-image__S5lyz {
        border-radius: 1rem 1rem 0 0;
        padding-top: 14rem
    }
    .merch-card_merch-unit--3-col__X7b2p .merch-card_merch-unit-content__OtFU9 {
        min-height: 9.5rem;
        padding: 1.5rem
    }
    .merch-card_merch-unit--3-col__X7b2p .merch-card_merch-unit-button__ZOIy0 {
        margin-top: .5rem
    }
    .merch-card_merch-unit--4-col__jpec6:first-child {
        grid-area: unit1
    }
    .merch-card_merch-unit--4-col__jpec6:nth-child(2) {
        grid-area: unit2
    }
    .merch-card_merch-unit--4-col__jpec6:nth-child(3) {
        grid-area: unit3
    }
    .merch-card_merch-unit--4-col__jpec6:nth-child(4) {
        grid-area: unit4
    }
    .merch-card_merch-unit--4-col__jpec6:first-child .merch-card_merch-unit-title__N6ksd {
        padding-bottom: .5rem
    }
    .merch-card_merch-unit--4-col__jpec6:first-child .merch-card_merch-unit-link__U4Zki {
        background-color: #f8f8f8;
        border-radius: 1rem;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        min-height: 19.0625rem
    }
    .merch-card_merch-unit--4-col__jpec6:first-child .merch-card_merch-unit-image__S5lyz {
        border-radius: 1rem 0 0 1rem !important
    }
    .merch-card_merch-unit--4-col__jpec6:first-child .merch-card_merch-unit-content__OtFU9 {
        padding: 4rem
    }
    .merch-card_merch-unit--4-col__jpec6:first-child .merch-card_merch-unit-description__iic_N {
        margin-bottom: 0
    }
    .merch-card_merch-unit--4-col__jpec6:first-child .merch-card_merch-unit-button__ZOIy0 {
        margin-top: auto
    }
    .merch-card_merch-unit--4-col__jpec6:nth-child(n+2) .merch-card_merch-unit-title__N6ksd {
        min-height: 3.5rem
    }
    .merch-card_merch-unit--4-col__jpec6:nth-child(n+2) .merch-card_merch-unit-link__U4Zki {
        display: inline-grid;
        min-height: 23.5rem
    }
    .merch-card_merch-unit--4-col__jpec6:nth-child(n+2) .merch-card_merch-unit-image__S5lyz {
        border-radius: 1rem 1rem 0 0;
        padding-top: 14rem
    }
    .merch-card_merch-unit--4-col__jpec6:nth-child(n+2) .merch-card_merch-unit-content__OtFU9 {
        min-height: 9.5rem;
        padding: 1.5rem
    }
    .merch-card_merch-unit--4-col__jpec6:nth-child(n+2) .merch-card_merch-unit-button__ZOIy0 {
        margin-top: .5rem
    }
}

.merch-unit_hide-x-overflow__84jbd {
    overflow-x: hidden
}

.merch-unit_homepageSection__pBKc8 {
    margin-bottom: 3.5rem
}

@media print,
screen and (min-width:64em) {
    .merch-unit_homepageSection__pBKc8 {
        margin-bottom: 6.5rem
    }
}

.merch-unit_homepageSectionExperiment__ShWXL {
    margin-bottom: 3.75rem
}

@media print,
screen and (min-width:64em) {
    .merch-unit_homepageSectionExperiment__ShWXL {
        margin-bottom: 6rem
    }
}

.merch-unit_heading__gLIEm {
    margin: 0 auto
}

.merch-unit_subheading__A_opR {
    margin-top: .5rem
}

.merch-unit_grid-columns__8QaMy {
    grid-gap: 2rem
}

.merch-unit_heading-1__jPD3V {
    font-size: 2.5rem;
    line-height: 1.1
}

.merch-unit_show-skeleton-loader__6PNIb {
    min-height: 37.5rem;
    position: relative
}

.merch-unit_skeleton-loader__Amy9y {
    background-color: #fff
}

.merch-unit_skeleton-loader--overlay__cMtG9 {
    bottom: 0;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .5s ease-out, visibility 0s .5s;
    width: 100%;
    z-index: 10
}

.merch-unit_skeleton-loader--done__sVqun {
    opacity: 0;
    visibility: hidden
}

.merch-unit_skeleton-loader-item__ZZSEp {
    background-color: #f8f8f8;
    border-radius: 1rem;
    cursor: wait;
    min-height: 21.875rem;
    -webkit-user-select: none;
    user-select: none
}

.merch-unit_skeleton-loader-item__ZZSEp>* {
    visibility: hidden
}

.merch-unit_skeleton-loader-item__ZZSEp:empty:after,
.merch-unit_skeleton-loader-item__ZZSEp>:empty:after {
    content: "Â "
}

@media screen and (max-width:47.99875em) {
    .merch-unit_skeleton-loader-item__ZZSEp:nth-child(n+4) {
        display: none
    }
}

.merch-unit_skeleton-loader-title__QG4M_ {
    background-color: #f8f8f8;
    border-radius: 6.25rem;
    color: #0000;
    padding: 1rem;
    width: -moz-fit-content;
    width: fit-content
}

.merch-unit_skeleton-loader-content__vYFeD,
.merch-unit_skeleton-loader-list__pbSur {
    height: 75%
}

@media print,
screen and (min-width:48em) {
    .merch-unit_skeleton-loader-list--overlay4x__O890o {
        width: 400%
    }
    .merch-unit_skeleton-loader-list__pbSur {
        display: flex
    }
}

.merch-unit_carousel__Crw93 {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none
}

.merch-unit_carousel__Crw93::-webkit-scrollbar {
    display: none
}

.merch-unit_carousel-item__GkXw1 {
    scroll-snap-align: start;
    scroll-snap-stop: always
}

.merch-unit_progress-dots__a9xrH {
    display: flex;
    flex-flow: row wrap;
    gap: .375rem;
    justify-content: flex-start;
    position: relative
}

.merch-unit_progress-dots__a9xrH:after,
.merch-unit_progress-dots__a9xrH:before {
    background-color: #333;
    border-radius: .1875rem;
    content: "";
    display: inline-flex;
    height: .375rem;
    margin-left: 0;
    position: absolute;
    width: 1.125rem;
    z-index: 2
}

@media(prefers-reduced-motion:no-preference) {
    .merch-unit_progress-dots__a9xrH:after {
        transition: margin-left .1s ease-out
    }
    .merch-unit_progress-dots__a9xrH:before {
        transition: margin-left .1s ease-out .2s
    }
}

.merch-unit_progress-dots__a9xrH[data-position="0"]:after,
.merch-unit_progress-dots__a9xrH[data-position="0"]:before {
    margin-left: 0
}

.merch-unit_progress-dots__a9xrH[data-position="1"]:after,
.merch-unit_progress-dots__a9xrH[data-position="1"]:before {
    margin-left: .75rem
}

.merch-unit_progress-dots__a9xrH[data-position="2"]:after,
.merch-unit_progress-dots__a9xrH[data-position="2"]:before {
    margin-left: 1.5rem
}

.merch-unit_progress-dots__a9xrH[data-position="3"]:after,
.merch-unit_progress-dots__a9xrH[data-position="3"]:before {
    margin-left: 2.25rem
}

.merch-unit_progress-dots-item__D4i_8 {
    background-color: #3333331a;
    border-radius: .1875rem;
    display: inline-flex;
    height: .375rem;
    transition: margin-left .3s ease-in-out;
    width: .375rem;
    z-index: 1
}

.merch-unit_header___jv_v {
    margin-bottom: 1.5rem
}

.merch-unit_heading__gLIEm {
    margin-top: .5rem
}

.merch-unit_title___13xz {
    margin-bottom: 2.5rem
}

@media screen and (max-width:47.99875em) {
    .merch-unit_merch-units-list__omQaI {
        display: flex;
        flex-flow: row nowrap
    }
    .merch-unit_grid-merch-units__rzvnV {
        gap: 1rem
    }
}

@media print,
screen and (min-width:48em) {
    .merch-unit_grid-merch-units__rzvnV {
        display: grid
    }
}

.merch-unit_grid-merch-units--1__i9zFC {
    grid-template-columns: minmax(0, 1fr)
}

.merch-unit_grid-merch-units--2__zY5Jc {
    grid-gap: 2.875rem;
    grid-template-columns: repeat(2, minmax(0, 1fr))
}

.merch-unit_grid-merch-units--3__g0RFc {
    grid-gap: 1.5rem;
    grid-template-columns: repeat(3, minmax(0, 1fr))
}

@media print,
screen and (min-width:48em) {
    .merch-unit_grid-merch-units--4__O6XWT {
        grid-column-gap: 1.5rem;
        grid-row-gap: 2.1875rem;
        grid-template: "unit1 unit1 unit1" "unit2 unit3 unit4";
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }
}

.merch-unit_progressDotsContainer__Y_QA3 {
    display: flex;
    justify-content: center
}

@keyframes mobile-fixed-start-button_fadeIn__2guK5 {
    0% {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes mobile-fixed-start-button_fadeOut__3ppEm {
    0% {
        opacity: 1
    }
    to {
        opacity: 0
    }
}

@keyframes mobile-fixed-start-button_fadeInAndUp__mBR03 {
    0% {
        opacity: 0;
        transform: translate3d(0, 1rem, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

@keyframes mobile-fixed-start-button_fadeOutAndUp__udVSr {
    0% {
        opacity: 1;
        transform: translateZ(0)
    }
    to {
        opacity: 0;
        transform: translate3d(0, -16px, 0)
    }
}

@keyframes mobile-fixed-start-button_bloop__Ruy_v {
    0% {
        opacity: 0;
        transform: scale(.1)
    }
    50% {
        opacity: 1;
        transform: scale(1.1)
    }
    to {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes mobile-fixed-start-button_clockwiseSpin__Zugdg {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes mobile-fixed-start-button_counterClockwiseSpin___58Qa {
    0% {
        transform: rotate(1turn)
    }
    to {
        transform: rotate(0deg)
    }
}

@keyframes mobile-fixed-start-button_snakeChase__0FebW {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }
    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }
    to {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }
}

@keyframes mobile-fixed-start-button_scaleUp__cGJDo {
    0% {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}

@keyframes mobile-fixed-start-button_scaleDown__g70Co {
    0% {
        transform: scale(1)
    }
    to {
        transform: scale(0)
    }
}

.mobile-fixed-start-button_startContainer__1Gb6m {
    animation: mobile-fixed-start-button_fadeInAndUp__mBR03 .5s;
    background-color: #fff;
    bottom: 0;
    box-shadow: 0 .375rem .875rem #0000001a;
    position: fixed;
    z-index: 99
}

.mobile-fixed-start-button_startButton__u7c6c {
    border: 1px solid #0000;
    border-radius: 1.5625rem;
    font-size: 1rem;
    justify-content: center;
    line-height: 1.5;
    padding: .6875rem 1rem;
    text-align: center
}

.trust-and-safety_hide-x-overflow__NEA7O {
    overflow-x: hidden
}

.trust-and-safety_homepageSection__3SpUn {
    margin-bottom: 3.5rem
}

@media print,
screen and (min-width:64em) {
    .trust-and-safety_homepageSection__3SpUn {
        margin-bottom: 6.5rem
    }
}

.trust-and-safety_homepageSectionExperiment__jJtgR {
    margin-bottom: 3.75rem
}

@media print,
screen and (min-width:64em) {
    .trust-and-safety_homepageSectionExperiment__jJtgR {
        margin-bottom: 6rem
    }
}

.trust-and-safety_heading__WBGc0 {
    margin: 0 auto
}

.trust-and-safety_subheading__mN0mF {
    margin-top: .5rem
}

.trust-and-safety_grid-columns__I29BS {
    grid-gap: 2rem
}

.trust-and-safety_heading-1__922Mo {
    font-size: 2.5rem;
    line-height: 1.1
}

.trust-and-safety_wrapper__qGmwc {
    display: grid
}

.trust-and-safety_background__A22kJ {
    background-color: #012d19;
    border-radius: 1.5rem;
    color: #fff;
    display: flex;
    grid-column: 1;
    grid-row: 1;
    height: 42.3125rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 100vw;
    width: 90%
}

@media(prefers-reduced-motion:no-preference) {
    .trust-and-safety_background__A22kJ {
        transition: border-radius .4s, width .2s
    }
}

.trust-and-safety_backgroundInView__cvwlc {
    border-radius: 0;
    width: 100%
}

@media print,
screen and (min-width:48em) {
    .trust-and-safety_background__A22kJ {
        height: 44rem
    }
}

.trust-and-safety_content__qoJOv {
    grid-column: 1;
    grid-row: 1;
    margin: auto;
    opacity: 0;
    transform: translateY(50%)
}

@media print,
screen and (min-width:64em) {
    .trust-and-safety_content__qoJOv {
        margin: auto
    }
}

@media(prefers-reduced-motion:no-preference) {
    .trust-and-safety_content__qoJOv {
        transition: transform .5s, opacity .5s
    }
}

.trust-and-safety_contentInView__ymq5S {
    opacity: 100%;
    transform: translateY(0)
}

.trust-and-safety_content__qoJOv a {
    color: #02a95c;
    text-decoration: underline
}

.trust-and-safety_text__DG7Mo {
    color: #fff;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 3.375rem
}

@media screen and (min-width:48em) {
    .trust-and-safety_text__DG7Mo {
        padding-right: calc(100% - 59.25rem)
    }
}

@media screen and (max-width:63.99875em) {
    .trust-and-safety_textSmallForSmall__XGpnp {
        font-size: 1.75rem;
        line-height: 2.375rem
    }
}

.trust-and-safety_opacity__6Gvp1 a {
    color: #fff
}

.video-light-box_videoWrapper__eDFiq {
    background-color: #000;
    border-radius: .75rem;
    display: flex;
    flex-direction: column;
    height: 48rem;
    left: 50%;
    max-height: 100%;
    max-width: 80rem;
    padding-bottom: 2rem;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 95%
}

.video-light-box_videoWrapper__eDFiq iframe {
    height: 100%;
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    padding: 0;
    width: 100%
}

@media screen and (max-width:47.99875em) {
    .video-light-box_videoWrapper__eDFiq {
        height: 30rem
    }
}

.video-light-box_closeBtn__X6hVN {
    color: #fff;
    margin: .25rem .25rem .25rem auto
}

.video-light-box_closeBtn__X6hVN:hover {
    color: #fff
}

.video_hide-x-overflow__9zYpW {
    overflow-x: hidden
}

.video_homepageSection__DJap3 {
    margin-bottom: 3.5rem
}

@media print,
screen and (min-width:64em) {
    .video_homepageSection__DJap3 {
        margin-bottom: 6.5rem
    }
}

.video_homepageSectionExperiment__fHQvK {
    margin-bottom: 3.75rem
}

@media print,
screen and (min-width:64em) {
    .video_homepageSectionExperiment__fHQvK {
        margin-bottom: 6rem
    }
}

.video_heading__v69fR {
    margin: 0 auto
}

.video_subheading__pLejj {
    margin-top: .5rem
}

.video_grid-columns__1nxsI {
    grid-gap: 2rem
}

.video_heading-1__40CT1 {
    font-size: 2.5rem;
    line-height: 1.1
}

.video_header__QhYpy {
    margin-bottom: 1.5rem
}

.video_videoHeading__btGfn {
    margin: 0 0 1rem
}

.video_learnMoreButton__NJ097 {
    border-radius: .625rem;
    height: -moz-fit-content;
    height: fit-content
}

@media screen and (max-width:47.99875em) {
    .video_learnMoreButtonTop__Sghdk {
        display: none
    }
}

.video_learnMoreButtonBottom__4SNcN {
    margin-top: 2.5rem
}

@media screen and (min-width:48em) {
    .video_learnMoreButtonBottom__4SNcN {
        display: none
    }
}

.video_videoPreviewContainer__waGpY {
    position: relative
}

.video_image__NT0b7 {
    border-radius: .75rem
}

@media screen and (max-width:47.99875em) {
    .video_image__NT0b7 {
        height: 31.25rem;
        object-fit: cover
    }
}

.video_icon__bn9t0 {
    margin-right: .5rem
}

.video_playButton__ZO5Lv {
    background-color: #fff;
    border: none;
    left: 50%;
    padding: .5rem 1.5rem;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap
}

.video_playButton__ZO5Lv:hover {
    background-color: #f8f8f8
}

.default-header-mobile_shared-default-header-mobile-button__rEUK0 {
    border-radius: 0;
    padding: 1rem;
    width: 100%
}

.default-header-mobile_shared-default-header-mobile-button__rEUK0:hover {
    background-color: #f1f1f1
}

.default-header-mobile_shared-default-header-mobile-drawer__lZ_AQ>header {
    margin-right: 1.25rem;
    margin-top: .5rem
}

.header_homepage-header-fixed__JbfMx {
    display: flex;
    flex-direction: column
}

@media screen and (min-width:48em) {
    .header_homepage-header-fixed__JbfMx {
        background-color: #f8f8f8
    }
}

.header_homepage-header-fixed__JbfMx nav {
    height: 3rem
}

.header_homepage-header-fixed--with-cookie-banner__0BuZO {
    left: auto;
    position: sticky;
    top: 0
}

.header_homepage-header-fixed__JbfMx.header_homepage-header-fixed--scrolled__B8vbC {
    background-color: #fff
}

.header-pill_homepage-header-fixed__MDtd9 {
    display: flex;
    flex-direction: column
}

@media screen and (min-width:48em) {
    .header-pill_homepage-header-fixed__MDtd9 {
        background-color: #f8f8f8
    }
}

.header-pill_homepage-header-fixed__MDtd9 nav {
    height: 3rem
}

.header-pill_homepage-header-fixed--with-cookie-banner__9SO76 {
    left: auto;
    position: sticky;
    top: 0
}

.header-pill_homepage-header-fixed__MDtd9.header-pill_homepage-header-fixed--scrolled__eIwYQ {
    background-color: #fff
}

.header-pill_homepage-header-fixed__MDtd9 nav {
    height: 3.5rem
}

.header-pill_homepage-header-animation--unscrolled__GULdP {
    max-width: 48rem
}

@media print,
screen and (min-width:48em) {
    .header-pill_homepage-header-fixed__MDtd9 {
        background-color: initial;
        padding-bottom: 1rem;
        padding-top: 1.5rem;
        transition: padding-top .2s, background-color 0ms
    }
    .header-pill_homepage-header-fixed--scrolled__eIwYQ {
        padding-bottom: 0;
        padding-top: 0
    }
    .header-pill_homepage-header-fixed--scrolled__eIwYQ:not([class*=homepage-header-fixed--shadow]) {
        background-color: #fff
    }
    .header-pill_homepage-header-animation___0utl {
        background-color: #fff;
        border-radius: 6.25rem;
        bottom: .5rem;
        height: 3.5rem;
        left: 0;
        margin: 0 auto;
        position: fixed;
        right: 0;
        top: 1.5rem;
        transition-duration: .2s;
        transition-property: max-width, top, bottom, border-radius, height;
        z-index: 1
    }
    .header-pill_homepage-header-animation--scrolled__qwguP {
        border-radius: 0;
        bottom: 0;
        max-width: 100%;
        top: 0
    }
}

@media print,
screen and (min-width:64em) {
    .header-pill_homepage-header-fixed__MDtd9[class*=homepage-header-fixed--scrolled] {
        padding-bottom: .5rem;
        padding-top: .5rem
    }
    .header-pill_homepage-header-fixed__MDtd9 nav {
        height: 4rem
    }
    .header-pill_homepage-header-animation___0utl {
        height: 4rem;
        top: 1.5rem
    }
    .header-pill_homepage-header-animation--scrolled__qwguP {
        height: 5rem;
        top: 0
    }
    .header-pill_homepage-header-animation--unscrolled__GULdP {
        max-width: 64rem
    }
}

@media screen and (min-width:72em) {
    .header-pill_homepage-header-animation--unscrolled__GULdP {
        max-width: 72rem
    }
}

@media only screen and (max-width:768px) {
    .header-pill_homepage-header-animation___0utl {
        max-width: 46rem
    }
    .header-pill_homepage-header-animation--scrolled__qwguP {
        max-width: 100%
    }
}

.locale-picker_localePickerButton__WsmkM {
    border-radius: .75rem;
    font-size: .875rem !important;
    font-weight: 400 !important;
    padding: .5rem !important
}

@media screen and (max-width:47.99875em) {
    .locale-picker_localePickerButton__WsmkM {
        width: 100%
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-more-resources {
    margin-top: -1rem;
    opacity: 0;
    transition: opacity .4s ease-in-out
}

@media screen and (min-width:48em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-more-resources {
        grid-column: 1/span 4
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-more-resources--open {
    opacity: 1
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-more-resources-button {
    font-weight: 600;
    grid-column-start: 1;
    justify-self: start;
    margin-left: -.5rem
}

@media screen and (min-width:48em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-more-resources-button {
        margin-top: 2rem
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-more-resources-list {
    margin: 0;
    padding-left: 0
}

@media screen and (min-width:48em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-more-resources-list {
        column-count: 4
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-secondary--with-app-stores {
    grid-template-areas: "locale" "social" "stores" "legal" !important
}

@media screen and (max-width:47.99875em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-secondary--with-app-stores {
        grid-gap: 1.5rem !important
    }
}

@media print,
screen and (min-width:48em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-secondary--with-app-stores {
        grid-template-areas: "locale social" "legal stores" !important
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-secondary-icons {
    margin-left: -.5rem
}

@media screen and (min-width:48em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-secondary-icons {
        margin-left: 0;
        margin-right: -.5rem
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-locale {
    grid-area: locale
}

@media screen and (max-width:47.99875em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-legal-date {
        margin-bottom: .5rem
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-stores {
    align-items: center;
    display: flex;
    grid-area: stores
}

@media print,
screen and (min-width:64em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-stores {
        justify-content: flex-end
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-store-button {
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 2.125rem;
    text-indent: -9999px
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-store-button--apple {
    height: 2.125rem;
    width: 6.375rem
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-store-button--google {
    height: 2.125rem;
    margin-right: .5rem;
    width: 7.3125rem
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-store-button--fr {
    width: 6.75rem
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-legal-list {
    flex-wrap: wrap;
    margin-left: -.5rem
}

@media print,
screen and (min-width:48em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-legal-item {
        margin-right: .5rem
    }
}

@media screen and (min-width:48em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-multicolumn {
        grid-column: 3/span 2
    }
    .default-footer_defaultFooter__ISvRD .shared-default-footer-multicolumn>ul {
        column-count: 2
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-list-header {
    margin-bottom: .25rem;
    margin-top: .5rem
}

@media screen and (min-width:48em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-list-header {
        margin-top: 0
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-listitem {
    margin-left: -.5rem;
    padding: .5rem
}

.home_footerContainer__UGfb8 {
    background-color: #fff;
    position: relative
}

.card-image_image___GL8n {
    height: 100%;
    object-fit: cover;
    position: absolute
}

.error_error__r5dDq {
    align-items: center;
    display: flex;
    font-size: .875rem;
    font-weight: 700;
    padding: 2.5rem 0
}

.error_error__r5dDq span {
    margin-left: .5rem
}

.error_dark__2TiVM {
    color: #fff
}

.error_light__KGFBI {
    color: #cf364a
}

@keyframes animations_phaseShift__iiY_T {
    0% {
        opacity: 1;
        transform: translateX(0)
    }
    69% {
        opacity: 0;
        transform: translateX(-3rem)
    }
    70% {
        opacity: 0;
        transform: translateX(1.5rem)
    }
    to {
        transform: translateX(0)
    }
}

.animations_phaseShiftCol2__sMscW,
.animations_phaseShift__iiY_T {
    animation: animations_phaseShift__iiY_T .42s
}

.animations_phaseShiftCol2__sMscW {
    animation-delay: 84ms
}

.animations_phaseShiftCol3__hzHD_ {
    animation: animations_phaseShift__iiY_T .42s;
    animation-delay: 168ms
}

@keyframes card_fadeIn__XxS5o {
    0% {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes card_fadeOut__NctDv {
    0% {
        opacity: 1
    }
    to {
        opacity: 0
    }
}

@keyframes card_fadeInAndUp__35MLY {
    0% {
        opacity: 0;
        transform: translate3d(0, 1rem, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

@keyframes card_fadeOutAndUp__q9AD1 {
    0% {
        opacity: 1;
        transform: translateZ(0)
    }
    to {
        opacity: 0;
        transform: translate3d(0, -16px, 0)
    }
}

@keyframes card_bloop__8EdWM {
    0% {
        opacity: 0;
        transform: scale(.1)
    }
    50% {
        opacity: 1;
        transform: scale(1.1)
    }
    to {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes card_clockwiseSpin__VaLhc {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes card_counterClockwiseSpin__FJdbZ {
    0% {
        transform: rotate(1turn)
    }
    to {
        transform: rotate(0deg)
    }
}

@keyframes card_snakeChase__Oy_2v {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }
    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }
    to {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }
}

@keyframes card_scaleUp__NDWBK {
    0% {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}

@keyframes card_scaleDown__ZP5zV {
    0% {
        transform: scale(1)
    }
    to {
        transform: scale(0)
    }
}

.card_container__TjAss {
    border-radius: .75rem;
    padding: .5rem;
    transition: background-color .3s;
    width: 100%
}

.card_container__TjAss img {
    transition: all .2s
}

.card_container__TjAss:hover img {
    transform: scale(1.05)
}

.card_imageContainer__2RJ99 {
    animation: card_fadeIn__XxS5o .5s;
    background: #f1f1f1;
    border-radius: .75rem;
    min-height: 10rem;
    overflow: hidden;
    position: relative
}

.card_mediaCardContent__GzKdX {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-around
}

.card_imageAvatar__kKQeQ {
    border: none;
    height: 1.25rem;
    margin-right: .5rem;
    width: 1.25rem
}

@media screen and (min-width:48em) {
    .card_imageAvatar__kKQeQ {
        display: none
    }
}

.card_insight__o2NTc {
    align-items: center;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: #00000080;
    border-radius: .75rem;
    bottom: .75rem;
    color: #fff;
    display: inline-flex;
    font-size: .875rem;
    font-weight: 400;
    height: 1.5rem;
    left: .75rem;
    padding: 0 .5rem;
    position: absolute
}

.card_insightSmall__m8Rnl {
    display: none
}

.card_link__KbsfM,
.card_title__NZDLT {
    text-decoration: none
}

.card_title__NZDLT {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 1rem;
    font-weight: 700;
    -webkit-line-clamp: 2;
    line-height: 1.3;
    overflow: hidden
}

@media screen and (max-width:47.99875em) {
    .card_title__NZDLT {
        font-size: .875rem;
        font-weight: 700;
        height: auto
    }
}

.card_coAndCategory___u9wR {
    display: flex;
    font-size: .875rem;
    font-weight: 400
}

@media print,
screen and (min-width:48em) {
    .card_coAndCategory___u9wR {
        margin-top: .5rem
    }
}

.card_coAndCategory___u9wR svg {
    margin-right: .5rem
}

@media screen and (min-width:48em) {
    .card_coAndCategory___u9wR svg {
        display: none
    }
}

.card_progressBarContainer__nl1hO {
    display: flex;
    flex-direction: column
}

.card_progressBar__guAuz {
    margin-top: 1.5rem
}

.card_progressBar__guAuz::-webkit-progress-value {
    -webkit-transition: none;
    transition: none
}

.card_progressData__kXM7x {
    display: flex;
    font-size: .875rem;
    justify-content: space-between;
    margin-top: .5rem
}

.card_distanceToGoal__Us3c_ {
    font-size: .875rem;
    font-weight: 700
}

.card_flexSpaced__rdiCG {
    align-items: flex-start;
    justify-content: space-between
}

.card_truncate__BPmA_ {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    max-height: 3.5em;
    overflow: hidden
}

.card_featured__nfenF {
    max-height: 36.125rem;
    max-width: 35.625rem
}

.card_featured__nfenF .card_imageContainer__2RJ99 {
    height: 27.4375rem
}

.card_regular__OKZ2g {
    height: 21.375rem;
    max-width: 16.9375rem
}

@media screen and (max-width:47.99875em) {
    .card_regular__OKZ2g {
        height: 21.125rem;
        max-width: 21.4375rem
    }
}

.card_light__V5idw {
    background: #0000;
    color: #333
}

.card_light__V5idw:hover {
    background: #f8f8f8
}

.card_light__V5idw.card_featured__nfenF .card_progressBar__guAuz {
    margin-top: 1.5rem
}

.card_light__V5idw .card_mediaCardContent__GzKdX {
    padding: 1.25rem .75rem
}

@media print,
screen and (min-width:48em) {
    .card_light__V5idw .card_title__NZDLT {
        height: 2.6rem
    }
}

.card_light__V5idw.card_regular__OKZ2g {
    height: 17.9375rem;
    max-width: 17.8125rem;
    padding: .5rem .5rem 1.25rem
}

.card_light__V5idw.card_regular__OKZ2g .card_imageContainer__2RJ99 {
    min-height: 9.25rem
}

@media screen and (max-width:47.99875em) {
    .card_light__V5idw.card_regular__OKZ2g {
        grid-template-columns: 1fr 3fr;
        height: -moz-fit-content;
        height: fit-content;
        max-width: unset;
        padding: .5rem
    }
    .card_light__V5idw.card_regular__OKZ2g .card_imageContainer__2RJ99 {
        height: 7rem;
        min-height: unset;
        width: 7rem
    }
    .card_light__V5idw.card_regular__OKZ2g .card_insight__o2NTc {
        display: none
    }
    .card_light__V5idw.card_regular__OKZ2g .card_insightSmall__m8Rnl {
        color: #767676;
        display: block;
        font-size: .875rem;
        font-weight: 400;
        margin-bottom: .25rem
    }
    .card_light__V5idw.card_regular__OKZ2g .card_progressBar__guAuz {
        margin-top: 1rem
    }
    .card_light__V5idw.card_regular__OKZ2g .card_mediaCardContent__GzKdX {
        justify-content: unset;
        margin: auto 0;
        padding: 0 0 0 1rem
    }
    .card_light__V5idw .card_mediaCardContentLoading__Og2jq {
        height: 100%;
        justify-content: space-around !important
    }
}

.card_light__V5idw .card_loadingTextBubble__nMao1 {
    background: #f1f1f1
}

@media screen and (max-width:47.99875em) {
    .card_light__V5idw .card_loadingTextBubble__nMao1 {
        margin-bottom: 0 !important
    }
}

.card_light__V5idw .card_progressBarContainerLoading__AeIkz {
    background: #f1f1f1;
    width: 50%
}

@media screen and (min-width:48em) {
    .card_light__V5idw .card_progressBarContainerLoading__AeIkz {
        width: 100%
    }
}

.card_dark__l2p4w {
    background: #012d19;
    color: #fff;
    height: unset;
    max-width: unset
}

.card_dark__l2p4w:hover {
    background: #0003
}

@media print,
screen and (min-width:48em) {
    .card_dark__l2p4w .card_mediaCardContent__GzKdX {
        min-height: 9.75rem
    }
    .card_dark__l2p4w .card_progressBarContainer__nl1hO {
        margin-top: auto
    }
}

.card_dark__l2p4w .card_progressBar__guAuz {
    background-color: #345747
}

.card_dark__l2p4w .card_progressBar__guAuz::-webkit-progress-bar {
    background-color: #345747
}

.card_dark__l2p4w .card_progressBar__guAuz::-ms-fill {
    background-color: #345747
}

@media print,
screen and (min-width:48em) {
    .card_dark__l2p4w .card_progressBar__guAuz {
        margin-top: unset
    }
}

.card_dark__l2p4w .card_progressData__kXM7x {
    color: #fff
}

.card_dark__l2p4w .card_imageContainer__2RJ99 {
    background: #0003
}

@media screen and (max-width:47.99875em) {
    .card_dark__l2p4w .card_imageContainer__2RJ99 {
        min-height: 12.5rem
    }
    .card_dark__l2p4w .card_mediaCardContentLoading__Og2jq {
        height: auto;
        justify-content: space-around !important
    }
}

.card_dark__l2p4w .card_loadingTextBubble__nMao1 {
    background: #0003
}

.card_dark__l2p4w .card_coAndCategoryLoading__GZy_C {
    background: #0003;
    border-radius: 1rem;
    height: 1.5rem;
    width: 50%
}

@media screen and (max-width:47.99875em) {
    .card_dark__l2p4w .card_coAndCategoryLoading__GZy_C {
        margin-top: .5rem
    }
}

.card_dark__l2p4w .card_progressBarContainerLoading__AeIkz {
    background: #0003;
    margin-top: .5rem;
    width: 100%
}

.card_dark__l2p4w .card_title__NZDLT {
    font-family: CircularXXWeb, Trebuchet, Helvetica, Arial, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
    font-weight: 700
}

@media screen and (max-width:47.99875em) {
    .card_dark__l2p4w .card_title__NZDLT {
        margin-bottom: .75rem;
        margin-top: .5rem
    }
}

.card_titleLoading__cGmE6 {
    margin-bottom: .5rem
}

@media screen and (max-width:47.99875em) {
    .card_titleLoading__cGmE6 {
        margin-bottom: 0 !important
    }
}

.card_loadingTextBubble__nMao1 {
    color: #f1f1f1 !important;
    width: max-content
}

.card_loadingTextBubble__nMao1,
.card_progressBarContainerLoading__AeIkz {
    border-radius: 1rem;
    height: 1.5rem
}

@keyframes animations_fadeIn__KHaJn {
    0% {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes animations_fadeOut__SwhlK {
    0% {
        opacity: 1
    }
    to {
        opacity: 0
    }
}

@keyframes animations_fadeInAndUp__N5aGe {
    0% {
        opacity: 0;
        transform: translate3d(0, 1rem, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

@keyframes animations_fadeOutAndUp__0DKUN {
    0% {
        opacity: 1;
        transform: translateZ(0)
    }
    to {
        opacity: 0;
        transform: translate3d(0, -16px, 0)
    }
}

@keyframes animations_bloop__D6TO5 {
    0% {
        opacity: 0;
        transform: scale(.1)
    }
    50% {
        opacity: 1;
        transform: scale(1.1)
    }
    to {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes animations_clockwiseSpin___v3Po {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes animations_counterClockwiseSpin__VH2fM {
    0% {
        transform: rotate(1turn)
    }
    to {
        transform: rotate(0deg)
    }
}

@keyframes animations_snakeChase__guaIb {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }
    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }
    to {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }
}

@keyframes animations_scaleUp__eK5kz {
    0% {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}

@keyframes animations_scaleDown__1YjCJ {
    0% {
        transform: scale(1)
    }
    to {
        transform: scale(0)
    }
}

.animations_fadeIn__KHaJn {
    animation: animations_fadeIn__KHaJn .5s cubic-bezier(.38, .41, .27, 1)
}

.animations_fadeOut__SwhlK {
    animation: animations_fadeOut__SwhlK .5s cubic-bezier(.38, .41, .27, 1)
}

@keyframes animations_openSlideUp__Zoz8G {
    0% {
        transform: translateY(100vh)
    }
    to {
        transform: translateY(0)
    }
}

@keyframes animations_closeSlideDown__Gsswv {
    0% {
        transform: translateY(0)
    }
    to {
        transform: translateY(100vh)
    }
}

.animations_openSlideUp__Zoz8G {
    animation: animations_openSlideUp__Zoz8G .5s cubic-bezier(.38, .41, .27, 1)
}

.animations_closeSlideDown__Gsswv {
    animation: animations_closeSlideDown__Gsswv .5s cubic-bezier(.38, .41, .27, 1)
}

@keyframes animations_openSlideDown__128Ei {
    0% {
        transform: translateY(-100vh)
    }
    to {
        transform: translateY(0)
    }
}

@keyframes animations_closeSlideUp__3V1cT {
    0% {
        transform: translateY(0)
    }
    to {
        transform: translateY(-100vh)
    }
}

.animations_openSlideDown__128Ei {
    animation: animations_openSlideDown__128Ei .5s cubic-bezier(.38, .41, .27, 1)
}

.animations_closeSlideUp__3V1cT {
    animation: animations_closeSlideUp__3V1cT .5s cubic-bezier(.38, .41, .27, 1)
}

@keyframes animations_openSlideLeft__s8Z_N {
    0% {
        transform: translateX(100vw)
    }
    to {
        transform: translateX(0)
    }
}

@keyframes animations_closeSlideRight___evjz {
    0% {
        transform: translateX(0)
    }
    to {
        transform: translateX(100vw)
    }
}

.animations_openSlideLeft__s8Z_N {
    animation: animations_openSlideLeft__s8Z_N .5s cubic-bezier(.38, .41, .27, 1)
}

.animations_closeSlideRight___evjz {
    animation: animations_closeSlideRight___evjz .5s cubic-bezier(.38, .41, .27, 1)
}

@keyframes animations_openSlideRight__c_Fcb {
    0% {
        transform: translateX(-100vw)
    }
    to {
        transform: translateX(0)
    }
}

@keyframes animations_closeSlideLeft__jkVcM {
    0% {
        transform: translateX(0)
    }
    to {
        transform: translateX(-100vw)
    }
}

.animations_openSlideRight__c_Fcb {
    animation: animations_openSlideRight__c_Fcb .5s cubic-bezier(.38, .41, .27, 1)
}

.animations_closeSlideLeft__jkVcM {
    animation: animations_closeSlideLeft__jkVcM .5s cubic-bezier(.38, .41, .27, 1)
}

.sheet_outer__4T3oF {
    position: fixed;
    z-index: 10000
}

.sheet_container__Oh1Kx {
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw
}

.sheet_container-openSlideUpBorders__q5UzQ {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem
}

.sheet_container-openSlideDownBorders__PKOya {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem
}

.sheet_container-openSlideLeftBorders__SHTWN {
    border-bottom-left-radius: .75rem;
    border-top-left-radius: .75rem
}

.sheet_container-openSlideRightBorders__b1P28 {
    border-bottom-right-radius: .75rem;
    border-top-right-radius: .75rem
}

.selector-modal_header__RSJ_n {
    align-items: center;
    border-bottom: .0625rem solid #e4e4e4;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 1.5rem
}

.selector-modal_close__KUC8c:active,
.selector-modal_close__KUC8c:focus {
    outline: none
}

.selector-modal_body__8lwM0 {
    overflow-y: scroll
}

.selector-modal_collectionsContainer__ttJ92 {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1rem .5rem
}

.selector-modal_collection__KcqFT {
    align-items: center;
    border-radius: .5rem;
    display: flex;
    gap: 1.25rem;
    list-style-type: none;
    padding: 1rem
}

.selector-modal_collection__KcqFT:hover {
    background: #f8f8f8;
    cursor: pointer
}

.selector-modal_icon__7BHMS {
    background: #f8f8f8;
    border-radius: 2.5rem;
    color: #333;
    height: 2.5rem;
    padding: .5rem;
    width: 2.5rem
}

.selector-modal_collectionText__T0pMJ {
    display: flex;
    flex-direction: column;
    gap: .25rem
}

.selector-modal_name__P93MR {
    color: #333;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem
}

.selector-modal_subtitle__tzJtU {
    color: #767676;
    font-size: .875rem;
    line-height: 1.3125rem
}

.selector-modal_USCitiesHeader__SU2gf {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 1rem
}

.selector-modal_USCities__3bhF7 {
    border-top: .0625rem solid #e4e4e4;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1rem .5rem
}

.selector-modal_trendingCities__IUp_0 {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.selector-modal_trendingCity__TQlB_ {
    align-items: center;
    border-radius: .5rem;
    display: flex;
    gap: .75rem;
    padding: 1rem
}

.selector-modal_trendingCity__TQlB_:hover {
    background: #f8f8f8;
    cursor: pointer
}

.selector-modal_locationIcon__IIzp_ {
    color: #333
}

.selector-modal_popper__8kVJw {
    background-color: #fff;
    border-radius: .75rem;
    box-shadow: 0 2px 6px #0000001a;
    height: 300px;
    overflow-y: scroll;
    position: absolute;
    top: 3rem;
    width: 23.5rem;
    z-index: 1
}

.selector-modal_popperHidden__tvNNB {
    visibility: hidden
}

.selector-modal_popperVisible__JFmXi {
    visibility: visible
}

.collection-selector_button__zBhnx {
    border-radius: 1.5rem
}

.collection-selector_arrow__EXuuD {
    margin: auto 0 auto .5rem;
    transition: transform .2s ease-in;
    width: 1rem
}

.collection-selector_rotate__ovZMg {
    transform: rotate(180deg)
}

.navigation_navigation__G5zeD {
    display: none
}

@media screen and (min-width:48em) {
    .navigation_navigation__G5zeD {
        display: flex
    }
}

.navigation_navigation__G5zeD button:first-child {
    margin-right: .5rem
}

.navigation_navigation__G5zeD.navigation_light__B7ltl button svg {
    color: #333
}

.navigation_navigation__G5zeD.navigation_light__B7ltl button.navigation_disabled__Q_dQh {
    border: 1px solid #e4e4e4
}

.navigation_navigation__G5zeD.navigation_light__B7ltl button.navigation_disabled__Q_dQh svg {
    color: #e4e4e4
}

.navigation_navigation__G5zeD.navigation_light__B7ltl button.navigation_disabled__Q_dQh:hover {
    border: 1px solid #e4e4e4;
    background: #fff;
    cursor: not-allowed
}

.navigation_navigation__G5zeD.navigation_light__B7ltl button.navigation_disabled__Q_dQh:hover svg {
    color: #e4e4e4
}

.navigation_navigation__G5zeD.navigation_light__B7ltl button:hover {
    border: 1px solid #c8c8c8
}

.navigation_navigation__G5zeD.navigation_light__B7ltl button:hover svg {
    color: #333
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button {
    background-color: #0000000d;
    border: 1px solid #fff6
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button svg {
    color: #fff
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button.navigation_disabled__Q_dQh {
    background-color: unset;
    border: 1px solid #fff3
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button.navigation_disabled__Q_dQh svg {
    color: #fff6
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button.navigation_disabled__Q_dQh:hover {
    background-color: unset;
    border: 1px solid #fff3;
    cursor: not-allowed
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button.navigation_disabled__Q_dQh:hover svg {
    color: #fff6
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button:hover {
    background-color: #ffffff0d;
    border: 1px solid #fff
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button:hover svg {
    color: #fff
}

.selector-header_container__k4kmu {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative
}

.collections_introText__CXqBN {
    color: #fff;
    margin-bottom: 3.5rem;
    width: 100%;
    font-family: CircularXXWeb, Trebuchet, Helvetica, Arial, sans-serif;
    font-size: clamp(1.75rem, 1.75rem + .75*(100vw - 23.4375rem)/66.5625, 2.5rem);
    font-weight: 600;
    letter-spacing: -.02em;
    line-height: 1.2
}

@media screen and (min-width:48em) {
    .collections_introText__CXqBN {
        flex-direction: row;
        margin-bottom: 5rem;
        width: 75%
    }
    .collections_container__KVJc_ {
        display: block
    }
}

.collections_header__Ni667 {
    color: #fff
}

@media screen and (min-width:48em) {
    .collections_header__Ni667 {
        max-width: 52.625rem
    }
}

.collections_cardsContainer__xPYeF {
    padding: 0
}

.collections_cardsContainer__xPYeF li {
    list-style-type: none;
    width: 100%
}

.collections_cardsContainer__xPYeF li:nth-of-type(1n+5) {
    display: none
}

.collections_showMoreButton__a4FoM {
    display: block;
    margin: 0
}

.collections_showMoreButton__a4FoM:hover {
    -webkit-backdrop-filter: brightness(1.2);
    backdrop-filter: brightness(1.2)
}

.collections_dark__sh9ba {
    background: #012d19
}

@media screen and (min-width:48em) {
    .collections_dark__sh9ba {
        margin: 0 -100%
    }
}

.collections_dark__sh9ba .collections_cardsContainer__xPYeF {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: space-between;
    margin: 2rem -.5rem
}

@media screen and (min-width:48em) {
    .collections_dark__sh9ba .collections_cardsContainer__xPYeF {
        flex-direction: row;
        gap: 1.5rem;
        justify-content: unset;
        margin-bottom: 0
    }
}

.collections_dark__sh9ba .collections_cardsContainer__xPYeF.collections_showAllCards__3s6hL li:nth-of-type(1n+5) {
    display: unset
}

.collections_dark__sh9ba .collections_showMoreButton__a4FoM {
    margin: 0 auto
}

.collections_light__O6Zbe {
    background: #fff
}

.collections_light__O6Zbe .collections_cardsContainer__xPYeF {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin: 1rem -.5rem
}

@media screen and (max-width:47.99875em) {
    .collections_light__O6Zbe .collections_cardsContainer__xPYeF {
        display: flex;
        flex-direction: column;
        margin: 2rem 0
    }
    .collections_light__O6Zbe .collections_cardsContainer__xPYeF li:nth-of-type(1n+4) {
        display: none
    }
    .collections_light__O6Zbe .collections_cardsContainer__xPYeF.collections_showAllCards__3s6hL li:nth-of-type(1n+4) {
        display: unset
    }
}

.collections_light__O6Zbe .collections_showMoreButton__a4FoM:hover {
    -webkit-backdrop-filter: brightness(1.2);
    backdrop-filter: brightness(1.2)
}

.collections_featuredRightList__2lwN0 {
    align-content: flex-start;
    display: grid;
    grid-gap: .125rem 0;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0
}

@media screen and (max-width:47.99875em) {
    .collections_featuredRightList__2lwN0 {
        display: flex;
        flex-direction: column;
        grid-gap: .5rem 0;
        grid-template-columns: unset;
        margin-left: -.5rem;
        margin-right: -.5rem
    }
}

.collections_popper__7R3dA {
    background-color: #fff;
    border-radius: .75rem;
    box-shadow: 0 2px 6px #0000001a;
    height: 300px;
    overflow-y: scroll;
    position: absolute;
    top: .5rem;
    width: 23.5rem
}

@media screen and (min-width:48em) {
    .collections_stage__tS4Qh {
        margin: 0 -.5rem;
        overflow: hidden;
        padding: 0 .5rem;
        width: 71rem
    }
}

.home-page_hide-x-overflow__dNETc {
    overflow-x: hidden
}

.home-page_homepageSection__Qrr1Z {
    margin-bottom: 3.5rem
}

@media print,
screen and (min-width:64em) {
    .home-page_homepageSection__Qrr1Z {
        margin-bottom: 6.5rem
    }
}

.home-page_homepageSectionExperiment__V5Ytj {
    margin-bottom: 3.75rem
}

@media print,
screen and (min-width:64em) {
    .home-page_homepageSectionExperiment__V5Ytj {
        margin-bottom: 6rem
    }
}

.home-page_heading__aBpke {
    margin: 0 auto
}

.home-page_subheading__mMYAg {
    margin-top: .5rem
}

.home-page_grid-columns__8FCPI {
    grid-gap: 2rem
}

.home-page_heading-1__5cozn {
    font-size: 2.5rem;
    line-height: 1.1
}

.home-page_container__5hXsR {
    background-color: #fff;
    border-top-left-radius: 1.875rem;
    border-top-right-radius: 1.875rem;
    padding-top: 2.5rem;
    position: relative;
    z-index: 1
}

@media screen and (max-width:47.99875em) {
    .home-page_container__5hXsR {
        padding-top: 1rem
    }
}

.home-page_collectionsHeading__umMyc {
    margin: 3rem 0 2.5rem
}

.home-page_homepageCollectionsSection__bdTrG {
    margin-bottom: 3.75rem
}

@media print,
screen and (min-width:64em) {
    .home-page_homepageCollectionsSection__bdTrG {
        margin-bottom: 7.25rem
    }
}

/*# sourceMappingURL=a12c3e2922e080c4.css.map*/