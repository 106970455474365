.galleryContainer {
    cursor: grab;
    z-index: 1;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.galleryContainer:active {
    cursor: grabbing;
}

.gradientMask {
    -webkit-box-shadow: inset 0px 0px 66px 34px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px 0px 66px 34px rgba(255, 255, 255, 1);
    box-shadow: inset 0px 0px 66px 34px rgba(255, 255, 255, 1);
}
