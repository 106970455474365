body {
    margin: 0;
    font-family: "Rubik", sans-serif;
    font-weight: 300;
    /* overflow: hidden; */
    /* touch-action: none; */
}

p {
    transform: scaleY(0.8) !important;
    margin: 0;
    word-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    transform: scaleY(0.8) !important;
}

html {
    background-color: white;
}

a {
    color: inherit;
}

input {
    box-sizing: border-box;
    width: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0.5px solid grey;
    border-radius: 3px;
}
input:focus {
    outline: none;
    box-shadow: none;
}

button {
    width: 100%;
    color: black;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0.5px solid grey;
    border-radius: 3px;
    background-color: rgb(238, 238, 238);
    padding: 4px 0;
    font-weight: inherit;
    cursor: pointer;
}
