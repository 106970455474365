.card-image_image___GL8n {
    height: 100%;
    object-fit: cover;
    position: absolute
}

.error_error__r5dDq {
    align-items: center;
    display: flex;
    font-size: .875rem;
    font-weight: 700;
    padding: 2.5rem 0
}

.error_error__r5dDq span {
    margin-left: .5rem
}

.error_dark__2TiVM {
    color: #fff
}

.error_light__KGFBI {
    color: #cf364a
}

@keyframes animations_phaseShift__iiY_T {
    0% {
        opacity: 1;
        transform: translateX(0)
    }
    69% {
        opacity: 0;
        transform: translateX(-3rem)
    }
    70% {
        opacity: 0;
        transform: translateX(1.5rem)
    }
    to {
        transform: translateX(0)
    }
}

.animations_phaseShiftCol2__sMscW,
.animations_phaseShift__iiY_T {
    animation: animations_phaseShift__iiY_T .42s
}

.animations_phaseShiftCol2__sMscW {
    animation-delay: 84ms
}

.animations_phaseShiftCol3__hzHD_ {
    animation: animations_phaseShift__iiY_T .42s;
    animation-delay: 168ms
}

@keyframes card_fadeIn__XxS5o {
    0% {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes card_fadeOut__NctDv {
    0% {
        opacity: 1
    }
    to {
        opacity: 0
    }
}

@keyframes card_fadeInAndUp__35MLY {
    0% {
        opacity: 0;
        transform: translate3d(0, 1rem, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

@keyframes card_fadeOutAndUp__q9AD1 {
    0% {
        opacity: 1;
        transform: translateZ(0)
    }
    to {
        opacity: 0;
        transform: translate3d(0, -16px, 0)
    }
}

@keyframes card_bloop__8EdWM {
    0% {
        opacity: 0;
        transform: scale(.1)
    }
    50% {
        opacity: 1;
        transform: scale(1.1)
    }
    to {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes card_clockwiseSpin__VaLhc {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes card_counterClockwiseSpin__FJdbZ {
    0% {
        transform: rotate(1turn)
    }
    to {
        transform: rotate(0deg)
    }
}

@keyframes card_snakeChase__Oy_2v {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }
    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }
    to {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }
}

@keyframes card_scaleUp__NDWBK {
    0% {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}

@keyframes card_scaleDown__ZP5zV {
    0% {
        transform: scale(1)
    }
    to {
        transform: scale(0)
    }
}

.card_container__TjAss {
    border-radius: .75rem;
    padding: .5rem;
    transition: background-color .3s;
    width: 100%
}

.card_container__TjAss img {
    transition: all .2s
}

.card_container__TjAss:hover img {
    transform: scale(1.05)
}

.card_imageContainer__2RJ99 {
    animation: card_fadeIn__XxS5o .5s;
    background: #f1f1f1;
    border-radius: .75rem;
    min-height: 10rem;
    overflow: hidden;
    position: relative
}

.card_mediaCardContent__GzKdX {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-around
}

.card_imageAvatar__kKQeQ {
    border: none;
    height: 1.25rem;
    margin-right: .5rem;
    width: 1.25rem
}

@media screen and (min-width:48em) {
    .card_imageAvatar__kKQeQ {
        display: none
    }
}

.card_insight__o2NTc {
    align-items: center;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: #00000080;
    border-radius: .75rem;
    bottom: .75rem;
    color: #fff;
    display: inline-flex;
    font-size: .875rem;
    font-weight: 400;
    height: 1.5rem;
    left: .75rem;
    padding: 0 .5rem;
    position: absolute
}

.card_insightSmall__m8Rnl {
    display: none
}

.card_link__KbsfM,
.card_title__NZDLT {
    text-decoration: none
}

.card_title__NZDLT {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 1rem;
    font-weight: 700;
    -webkit-line-clamp: 2;
    line-height: 1.3;
    overflow: hidden
}

@media screen and (max-width:47.99875em) {
    .card_title__NZDLT {
        font-size: .875rem;
        font-weight: 700;
        height: auto
    }
}

.card_coAndCategory___u9wR {
    display: flex;
    font-size: .875rem;
    font-weight: 400
}

@media print,
screen and (min-width:48em) {
    .card_coAndCategory___u9wR {
        margin-top: .5rem
    }
}

.card_coAndCategory___u9wR svg {
    margin-right: .5rem
}

@media screen and (min-width:48em) {
    .card_coAndCategory___u9wR svg {
        display: none
    }
}

.card_progressBarContainer__nl1hO {
    display: flex;
    flex-direction: column
}

.card_progressBar__guAuz {
    margin-top: 1.5rem
}

.card_progressBar__guAuz::-webkit-progress-value {
    -webkit-transition: none;
    transition: none
}

.card_progressData__kXM7x {
    display: flex;
    font-size: .875rem;
    justify-content: space-between;
    margin-top: .5rem
}

.card_distanceToGoal__Us3c_ {
    font-size: .875rem;
    font-weight: 700
}

.card_flexSpaced__rdiCG {
    align-items: flex-start;
    justify-content: space-between
}

.card_truncate__BPmA_ {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    max-height: 3.5em;
    overflow: hidden
}

.card_featured__nfenF {
    max-height: 36.125rem;
    max-width: 35.625rem
}

.card_featured__nfenF .card_imageContainer__2RJ99 {
    height: 27.4375rem
}

.card_regular__OKZ2g {
    height: 21.375rem;
    max-width: 16.9375rem
}

@media screen and (max-width:47.99875em) {
    .card_regular__OKZ2g {
        height: 21.125rem;
        max-width: 21.4375rem
    }
}

.card_light__V5idw {
    background: #0000;
    color: #333
}

.card_light__V5idw:hover {
    background: #f8f8f8
}

.card_light__V5idw.card_featured__nfenF .card_progressBar__guAuz {
    margin-top: 1.5rem
}

.card_light__V5idw .card_mediaCardContent__GzKdX {
    padding: 1.25rem .75rem
}

@media print,
screen and (min-width:48em) {
    .card_light__V5idw .card_title__NZDLT {
        height: 2.6rem
    }
}

.card_light__V5idw.card_regular__OKZ2g {
    height: 17.9375rem;
    max-width: 17.8125rem;
    padding: .5rem .5rem 1.25rem
}

.card_light__V5idw.card_regular__OKZ2g .card_imageContainer__2RJ99 {
    min-height: 9.25rem
}

@media screen and (max-width:47.99875em) {
    .card_light__V5idw.card_regular__OKZ2g {
        grid-template-columns: 1fr 3fr;
        height: -moz-fit-content;
        height: fit-content;
        max-width: unset;
        padding: .5rem
    }
    .card_light__V5idw.card_regular__OKZ2g .card_imageContainer__2RJ99 {
        height: 7rem;
        min-height: unset;
        width: 7rem
    }
    .card_light__V5idw.card_regular__OKZ2g .card_insight__o2NTc {
        display: none
    }
    .card_light__V5idw.card_regular__OKZ2g .card_insightSmall__m8Rnl {
        color: #767676;
        display: block;
        font-size: .875rem;
        font-weight: 400;
        margin-bottom: .25rem
    }
    .card_light__V5idw.card_regular__OKZ2g .card_progressBar__guAuz {
        margin-top: 1rem
    }
    .card_light__V5idw.card_regular__OKZ2g .card_mediaCardContent__GzKdX {
        justify-content: unset;
        margin: auto 0;
        padding: 0 0 0 1rem
    }
    .card_light__V5idw .card_mediaCardContentLoading__Og2jq {
        height: 100%;
        justify-content: space-around !important
    }
}

.card_light__V5idw .card_loadingTextBubble__nMao1 {
    background: #f1f1f1
}

@media screen and (max-width:47.99875em) {
    .card_light__V5idw .card_loadingTextBubble__nMao1 {
        margin-bottom: 0 !important
    }
}

.card_light__V5idw .card_progressBarContainerLoading__AeIkz {
    background: #f1f1f1;
    width: 50%
}

@media screen and (min-width:48em) {
    .card_light__V5idw .card_progressBarContainerLoading__AeIkz {
        width: 100%
    }
}

.card_dark__l2p4w {
    background: #012d19;
    color: #fff;
    height: unset;
    max-width: unset
}

.card_dark__l2p4w:hover {
    background: #0003
}

@media print,
screen and (min-width:48em) {
    .card_dark__l2p4w .card_mediaCardContent__GzKdX {
        min-height: 9.75rem
    }
    .card_dark__l2p4w .card_progressBarContainer__nl1hO {
        margin-top: auto
    }
}

.card_dark__l2p4w .card_progressBar__guAuz {
    background-color: #345747
}

.card_dark__l2p4w .card_progressBar__guAuz::-webkit-progress-bar {
    background-color: #345747
}

.card_dark__l2p4w .card_progressBar__guAuz::-ms-fill {
    background-color: #345747
}

@media print,
screen and (min-width:48em) {
    .card_dark__l2p4w .card_progressBar__guAuz {
        margin-top: unset
    }
}

.card_dark__l2p4w .card_progressData__kXM7x {
    color: #fff
}

.card_dark__l2p4w .card_imageContainer__2RJ99 {
    background: #0003
}

@media screen and (max-width:47.99875em) {
    .card_dark__l2p4w .card_imageContainer__2RJ99 {
        min-height: 12.5rem
    }
    .card_dark__l2p4w .card_mediaCardContentLoading__Og2jq {
        height: auto;
        justify-content: space-around !important
    }
}

.card_dark__l2p4w .card_loadingTextBubble__nMao1 {
    background: #0003
}

.card_dark__l2p4w .card_coAndCategoryLoading__GZy_C {
    background: #0003;
    border-radius: 1rem;
    height: 1.5rem;
    width: 50%
}

@media screen and (max-width:47.99875em) {
    .card_dark__l2p4w .card_coAndCategoryLoading__GZy_C {
        margin-top: .5rem
    }
}

.card_dark__l2p4w .card_progressBarContainerLoading__AeIkz {
    background: #0003;
    margin-top: .5rem;
    width: 100%
}

.card_dark__l2p4w .card_title__NZDLT {
    font-family: CircularXXWeb, Trebuchet, Helvetica, Arial, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
    font-weight: 700
}

@media screen and (max-width:47.99875em) {
    .card_dark__l2p4w .card_title__NZDLT {
        margin-bottom: .75rem;
        margin-top: .5rem
    }
}

.card_titleLoading__cGmE6 {
    margin-bottom: .5rem
}

@media screen and (max-width:47.99875em) {
    .card_titleLoading__cGmE6 {
        margin-bottom: 0 !important
    }
}

.card_loadingTextBubble__nMao1 {
    color: #f1f1f1 !important;
    width: max-content
}

.card_loadingTextBubble__nMao1,
.card_progressBarContainerLoading__AeIkz {
    border-radius: 1rem;
    height: 1.5rem
}

@keyframes animations_fadeIn__KHaJn {
    0% {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes animations_fadeOut__SwhlK {
    0% {
        opacity: 1
    }
    to {
        opacity: 0
    }
}

@keyframes animations_fadeInAndUp__N5aGe {
    0% {
        opacity: 0;
        transform: translate3d(0, 1rem, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

@keyframes animations_fadeOutAndUp__0DKUN {
    0% {
        opacity: 1;
        transform: translateZ(0)
    }
    to {
        opacity: 0;
        transform: translate3d(0, -16px, 0)
    }
}

@keyframes animations_bloop__D6TO5 {
    0% {
        opacity: 0;
        transform: scale(.1)
    }
    50% {
        opacity: 1;
        transform: scale(1.1)
    }
    to {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes animations_clockwiseSpin___v3Po {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes animations_counterClockwiseSpin__VH2fM {
    0% {
        transform: rotate(1turn)
    }
    to {
        transform: rotate(0deg)
    }
}

@keyframes animations_snakeChase__guaIb {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }
    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }
    to {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }
}

@keyframes animations_scaleUp__eK5kz {
    0% {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}

@keyframes animations_scaleDown__1YjCJ {
    0% {
        transform: scale(1)
    }
    to {
        transform: scale(0)
    }
}

.animations_fadeIn__KHaJn {
    animation: animations_fadeIn__KHaJn .5s cubic-bezier(.38, .41, .27, 1)
}

.animations_fadeOut__SwhlK {
    animation: animations_fadeOut__SwhlK .5s cubic-bezier(.38, .41, .27, 1)
}

@keyframes animations_openSlideUp__Zoz8G {
    0% {
        transform: translateY(100vh)
    }
    to {
        transform: translateY(0)
    }
}

@keyframes animations_closeSlideDown__Gsswv {
    0% {
        transform: translateY(0)
    }
    to {
        transform: translateY(100vh)
    }
}

.animations_openSlideUp__Zoz8G {
    animation: animations_openSlideUp__Zoz8G .5s cubic-bezier(.38, .41, .27, 1)
}

.animations_closeSlideDown__Gsswv {
    animation: animations_closeSlideDown__Gsswv .5s cubic-bezier(.38, .41, .27, 1)
}

@keyframes animations_openSlideDown__128Ei {
    0% {
        transform: translateY(-100vh)
    }
    to {
        transform: translateY(0)
    }
}

@keyframes animations_closeSlideUp__3V1cT {
    0% {
        transform: translateY(0)
    }
    to {
        transform: translateY(-100vh)
    }
}

.animations_openSlideDown__128Ei {
    animation: animations_openSlideDown__128Ei .5s cubic-bezier(.38, .41, .27, 1)
}

.animations_closeSlideUp__3V1cT {
    animation: animations_closeSlideUp__3V1cT .5s cubic-bezier(.38, .41, .27, 1)
}

@keyframes animations_openSlideLeft__s8Z_N {
    0% {
        transform: translateX(100vw)
    }
    to {
        transform: translateX(0)
    }
}

@keyframes animations_closeSlideRight___evjz {
    0% {
        transform: translateX(0)
    }
    to {
        transform: translateX(100vw)
    }
}

.animations_openSlideLeft__s8Z_N {
    animation: animations_openSlideLeft__s8Z_N .5s cubic-bezier(.38, .41, .27, 1)
}

.animations_closeSlideRight___evjz {
    animation: animations_closeSlideRight___evjz .5s cubic-bezier(.38, .41, .27, 1)
}

@keyframes animations_openSlideRight__c_Fcb {
    0% {
        transform: translateX(-100vw)
    }
    to {
        transform: translateX(0)
    }
}

@keyframes animations_closeSlideLeft__jkVcM {
    0% {
        transform: translateX(0)
    }
    to {
        transform: translateX(-100vw)
    }
}

.animations_openSlideRight__c_Fcb {
    animation: animations_openSlideRight__c_Fcb .5s cubic-bezier(.38, .41, .27, 1)
}

.animations_closeSlideLeft__jkVcM {
    animation: animations_closeSlideLeft__jkVcM .5s cubic-bezier(.38, .41, .27, 1)
}

.sheet_outer__4T3oF {
    position: fixed;
    z-index: 10000
}

.sheet_container__Oh1Kx {
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw
}

.sheet_container-openSlideUpBorders__q5UzQ {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem
}

.sheet_container-openSlideDownBorders__PKOya {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem
}

.sheet_container-openSlideLeftBorders__SHTWN {
    border-bottom-left-radius: .75rem;
    border-top-left-radius: .75rem
}

.sheet_container-openSlideRightBorders__b1P28 {
    border-bottom-right-radius: .75rem;
    border-top-right-radius: .75rem
}

.selector-modal_header__RSJ_n {
    align-items: center;
    border-bottom: .0625rem solid #e4e4e4;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 1.5rem
}

.selector-modal_close__KUC8c:active,
.selector-modal_close__KUC8c:focus {
    outline: none
}

.selector-modal_body__8lwM0 {
    overflow-y: scroll
}

.selector-modal_collectionsContainer__ttJ92 {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1rem .5rem
}

.selector-modal_collection__KcqFT {
    align-items: center;
    border-radius: .5rem;
    display: flex;
    gap: 1.25rem;
    list-style-type: none;
    padding: 1rem
}

.selector-modal_collection__KcqFT:hover {
    background: #f8f8f8;
    cursor: pointer
}

.selector-modal_icon__7BHMS {
    background: #f8f8f8;
    border-radius: 2.5rem;
    color: #333;
    height: 2.5rem;
    padding: .5rem;
    width: 2.5rem
}

.selector-modal_collectionText__T0pMJ {
    display: flex;
    flex-direction: column;
    gap: .25rem
}

.selector-modal_name__P93MR {
    color: #333;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem
}

.selector-modal_subtitle__tzJtU {
    color: #767676;
    font-size: .875rem;
    line-height: 1.3125rem
}

.selector-modal_USCitiesHeader__SU2gf {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 1rem
}

.selector-modal_USCities__3bhF7 {
    border-top: .0625rem solid #e4e4e4;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1rem .5rem
}

.selector-modal_trendingCities__IUp_0 {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.selector-modal_trendingCity__TQlB_ {
    align-items: center;
    border-radius: .5rem;
    display: flex;
    gap: .75rem;
    padding: 1rem
}

.selector-modal_trendingCity__TQlB_:hover {
    background: #f8f8f8;
    cursor: pointer
}

.selector-modal_locationIcon__IIzp_ {
    color: #333
}

.selector-modal_popper__8kVJw {
    background-color: #fff;
    border-radius: .75rem;
    box-shadow: 0 2px 6px #0000001a;
    height: 300px;
    overflow-y: scroll;
    position: absolute;
    top: 3rem;
    width: 23.5rem;
    z-index: 1
}

.selector-modal_popperHidden__tvNNB {
    visibility: hidden
}

.selector-modal_popperVisible__JFmXi {
    visibility: visible
}

.collection-selector_button__zBhnx {
    border-radius: 1.5rem
}

.collection-selector_arrow__EXuuD {
    margin: auto 0 auto .5rem;
    transition: transform .2s ease-in;
    width: 1rem
}

.collection-selector_rotate__ovZMg {
    transform: rotate(180deg)
}

.navigation_navigation__G5zeD {
    display: none
}

@media screen and (min-width:48em) {
    .navigation_navigation__G5zeD {
        display: flex
    }
}

.navigation_navigation__G5zeD button:first-child {
    margin-right: .5rem
}

.navigation_navigation__G5zeD.navigation_light__B7ltl button svg {
    color: #333
}

.navigation_navigation__G5zeD.navigation_light__B7ltl button.navigation_disabled__Q_dQh {
    border: 1px solid #e4e4e4
}

.navigation_navigation__G5zeD.navigation_light__B7ltl button.navigation_disabled__Q_dQh svg {
    color: #e4e4e4
}

.navigation_navigation__G5zeD.navigation_light__B7ltl button.navigation_disabled__Q_dQh:hover {
    border: 1px solid #e4e4e4;
    background: #fff;
    cursor: not-allowed
}

.navigation_navigation__G5zeD.navigation_light__B7ltl button.navigation_disabled__Q_dQh:hover svg {
    color: #e4e4e4
}

.navigation_navigation__G5zeD.navigation_light__B7ltl button:hover {
    border: 1px solid #c8c8c8
}

.navigation_navigation__G5zeD.navigation_light__B7ltl button:hover svg {
    color: #333
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button {
    background-color: #0000000d;
    border: 1px solid #fff6
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button svg {
    color: #fff
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button.navigation_disabled__Q_dQh {
    background-color: unset;
    border: 1px solid #fff3
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button.navigation_disabled__Q_dQh svg {
    color: #fff6
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button.navigation_disabled__Q_dQh:hover {
    background-color: unset;
    border: 1px solid #fff3;
    cursor: not-allowed
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button.navigation_disabled__Q_dQh:hover svg {
    color: #fff6
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button:hover {
    background-color: #ffffff0d;
    border: 1px solid #fff
}

.navigation_navigation__G5zeD.navigation_dark__2Yx_Z button:hover svg {
    color: #fff
}

.selector-header_container__k4kmu {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative
}

.collections_introText__CXqBN {
    color: #fff;
    margin-bottom: 3.5rem;
    width: 100%;
    font-family: CircularXXWeb, Trebuchet, Helvetica, Arial, sans-serif;
    font-size: clamp(1.75rem, 1.75rem + .75*(100vw - 23.4375rem)/66.5625, 2.5rem);
    font-weight: 600;
    letter-spacing: -.02em;
    line-height: 1.2
}

@media screen and (min-width:48em) {
    .collections_introText__CXqBN {
        flex-direction: row;
        margin-bottom: 5rem;
        width: 75%
    }
    .collections_container__KVJc_ {
        display: block
    }
}

.collections_header__Ni667 {
    color: #fff
}

@media screen and (min-width:48em) {
    .collections_header__Ni667 {
        max-width: 52.625rem
    }
}

.collections_cardsContainer__xPYeF {
    padding: 0
}

.collections_cardsContainer__xPYeF li {
    list-style-type: none;
    width: 100%
}

.collections_cardsContainer__xPYeF li:nth-of-type(1n+5) {
    display: none
}

.collections_showMoreButton__a4FoM {
    display: block;
    margin: 0
}

.collections_showMoreButton__a4FoM:hover {
    -webkit-backdrop-filter: brightness(1.2);
    backdrop-filter: brightness(1.2)
}

.collections_dark__sh9ba {
    background: #012d19
}

@media screen and (min-width:48em) {
    .collections_dark__sh9ba {
        margin: 0 -100%
    }
}

.collections_dark__sh9ba .collections_cardsContainer__xPYeF {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: space-between;
    margin: 2rem -.5rem
}

@media screen and (min-width:48em) {
    .collections_dark__sh9ba .collections_cardsContainer__xPYeF {
        flex-direction: row;
        gap: 1.5rem;
        justify-content: unset;
        margin-bottom: 0
    }
}

.collections_dark__sh9ba .collections_cardsContainer__xPYeF.collections_showAllCards__3s6hL li:nth-of-type(1n+5) {
    display: unset
}

.collections_dark__sh9ba .collections_showMoreButton__a4FoM {
    margin: 0 auto
}

.collections_light__O6Zbe {
    background: #fff
}

.collections_light__O6Zbe .collections_cardsContainer__xPYeF {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin: 1rem -.5rem
}

@media screen and (max-width:47.99875em) {
    .collections_light__O6Zbe .collections_cardsContainer__xPYeF {
        display: flex;
        flex-direction: column;
        margin: 2rem 0
    }
    .collections_light__O6Zbe .collections_cardsContainer__xPYeF li:nth-of-type(1n+4) {
        display: none
    }
    .collections_light__O6Zbe .collections_cardsContainer__xPYeF.collections_showAllCards__3s6hL li:nth-of-type(1n+4) {
        display: unset
    }
}

.collections_light__O6Zbe .collections_showMoreButton__a4FoM:hover {
    -webkit-backdrop-filter: brightness(1.2);
    backdrop-filter: brightness(1.2)
}

.collections_featuredRightList__2lwN0 {
    align-content: flex-start;
    display: grid;
    grid-gap: .125rem 0;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0
}

@media screen and (max-width:47.99875em) {
    .collections_featuredRightList__2lwN0 {
        display: flex;
        flex-direction: column;
        grid-gap: .5rem 0;
        grid-template-columns: unset;
        margin-left: -.5rem;
        margin-right: -.5rem
    }
}

.collections_popper__7R3dA {
    background-color: #fff;
    border-radius: .75rem;
    box-shadow: 0 2px 6px #0000001a;
    height: 300px;
    overflow-y: scroll;
    position: absolute;
    top: .5rem;
    width: 23.5rem
}

@media screen and (min-width:48em) {
    .collections_stage__tS4Qh {
        margin: 0 -.5rem;
        overflow: hidden;
        padding: 0 .5rem;
        width: 71rem
    }
}

/*# sourceMappingURL=0ec3d6e62b58491c.css.map*/