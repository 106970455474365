.navBar {
    width: 100%;
    height: 12vh;
    position: fixed;
    display: grid;
    place-items: center;
    bottom: 0;
    z-index: 2;
    /* background-color: blueviolet; */
}

.copyright {
    margin-top: 48px;
    font-size: 0.1em;
}

.navContents,
.navMenu {
    width: 90%;
    /* height: 80%; */
    transform: scaleY(0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    /* background-color: antiquewhite; */
}

.socialLinks {
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 2em;
    gap: 8px;
}

.socialLinks > a {
    display: contents;
}

.navItem {
    padding: 2em;
    cursor: pointer;
}

.link {
    text-decoration: none;
    color: black;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    border: none;
}

.link:hover {
    opacity: 0.7;
}

.sandwichIcon {
    display: none;
}

.exit {
    color: black;
    position: absolute;
    top: 0;
    right: 0;
    padding: 48px;
    /* rotate: 45deg; */
    cursor: pointer;
    transform: scaleY(0.8);
}

.open {
    top: 0;
}

.mobile-menu {
    display: none;
}

/* Mobile */

@media only screen and (max-width: 768px) {
    .navBar {
        top: 0;
    }

    .navMenu {
        justify-content: space-between;
        display: flex;
        flex-direction: row;
    }

    .navContents {
        display: none;
    }

    .sandwichIcon {
        display: flex;
    }

    .sandwichIcon {
        width: 48px;
        height: 48px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .mobile-menu {
        position: fixed;
        top: -150%;
        left: 0;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        color: var(--white);
        transition: top 0.3s ease-in-out;
        height: 100vh;
        display: flex;
        flex-direction: column;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        /* padding-top: 20%; */
        justify-content: center;
        z-index: 100;
        -ms-transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .mobile-menu > .navItem {
        color: var(--white);
    }

    .mobileNavLinks {
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: center;
        text-align: center;
    }

    .mobileNavItem {
        font-size: 5rem;
        /* font-weight: 700; */
        transform: scaleY(0.8);
    }

    .exit {
        color: var(--black);
        position: absolute;
        top: 0;
        right: 0;
        padding: 48px 40px;
        /* rotate: 45deg; */
        transform: scaleY(0.8);
        cursor: pointer;
    }

    .open {
        top: 0;
    }

    .socialLinks {
        width: 60%;
        margin: 48px auto 0 auto;
    }
}
