html {
    /* background: rgb(44, 155, 31); */
}

.pageContainer {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    /* background-color: rgb(44, 155, 31); */
    color: greenyellow;
}

.paragraphSpacing {
    text-align: justify;
    text-align-last: justify;
}

.ctaLink {
    padding: 4px;
    position: absolute;
    right: 0;
    top: 50%;
    background-color: black;
    text-align: center;
    color: whitesmoke;
    cursor: pointer;
    transform: rotate(-90deg);
    width: fit-content;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
}

.ritualPage {
    font-family: Andale Mono, monospace;
    white-space: wrap;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: small;
    padding: 8px;
    height: 100%;
}

.introText {
}

.text-art {
    font-size: 10px;
    display: flex;
    align-items: right;
    justify-content: right;
    margin-bottom: 20px;
    overflow: hidden;
}

@media screen and (max-width: 1000px) {
    .text-art {
        align-items: center;
        justify-content: center;
    }
}
