.newsletter-modal {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    opacity: 1;

    transition: all ease-in-out 0.2s;
    display: flex;
    cursor: default !important;

    form {
        display: flex;
        flex-direction: column;
        width: inherit;
        align-items: center;
        justify-content: center;
        gap: 4px;
        margin: 0 auto;
        margin-top: 16px;
        max-width: 400px;
    }

    input[type="email"] {
        width: inherit;
        font-size: inherit;
        padding: 2px 4px;
    }

    input[type="submit"] {
        cursor: pointer;
        width: 100%;
        font-size: inherit;
    }
}
