.accelerometer-modal {
    width: 100%;
    height: 100%;
}

#permissions-modal {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
    z-index: 111;
    display: flex;
}

#modal-content {
    padding: 0 16px;
    button {
        width: 100%;
        margin-top: 16px;
        color: black;
        appearance: none;
        border: 0.5px solid grey;
        border-radius: 3px;
    }
}
