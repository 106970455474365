.gfm-donate-button {
    border: 0
}

.gfm-donate-button:not(.hrt-primary-button--disabled) {
    background: #f99a32;
    background: linear-gradient(180deg, #fdb933, #f99a32);
    color: #333
}

.gfm-donate-button:not(.hrt-primary-button--disabled).sb-hover,
.gfm-donate-button:not(.hrt-primary-button--disabled):active,
.gfm-donate-button:not(.hrt-primary-button--disabled):hover {
    background: #fdb933;
    background: linear-gradient(180deg, #f99a32 50.52%, #fdb933);
    color: #333
}

.gfm-fundraiser-button:not(.hrt-primary-button--disabled) {
    background-color: #008748;
    color: #fff
}

.gfm-fundraiser-button:not(.hrt-primary-button--disabled).sb-hover,
.gfm-fundraiser-button:not(.hrt-primary-button--disabled):hover {
    background-color: #006e3a;
    color: #fff
}

.gfm-share-button {
    border: 0
}

.gfm-share-button:not(.hrt-primary-button--disabled) {
    background: #f3bc51;
    background: linear-gradient(180deg, #f9db74, #f3bc51);
    color: #333
}

.gfm-share-button:not(.hrt-primary-button--disabled).sb-hover,
.gfm-share-button:not(.hrt-primary-button--disabled):active,
.gfm-share-button:not(.hrt-primary-button--disabled):hover {
    background: #f9db74;
    background: linear-gradient(180deg, #f3bc51 50%, #f9db74);
    color: #333
}

.gfm-fundraiser-card {
    background-color: initial;
    border: 1px solid #0000;
    display: flex;
    padding: .5rem;
    text-align: left;
    transition-duration: .5s;
    transition-property: background-color, border-color;
    transition-timing-function: cubic-bezier(.3, .01, 0, 1)
}

.gfm-fundraiser-card .hrt-media-tag {
    transition: color .5s cubic-bezier(.3, .01, 0, 1)
}

.gfm-fundraiser-card.sb-hover .gfm-fundraiser-card-image,
.gfm-fundraiser-card:hover .gfm-fundraiser-card-image {
    transform: scale(1.05)
}

.gfm-fundraiser-card--size-default {
    border-radius: 1.25rem;
    flex-direction: column
}

.gfm-fundraiser-card--size-default .gfm-fundraiser-card-image-container {
    aspect-ratio: 3/2;
    border-radius: 1rem;
    max-width: 100%
}

.gfm-fundraiser-card--size-default .gfm-fundraiser-card-content {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem
}

.gfm-fundraiser-card--size-default .gfm-fundraiser-card-body-text {
    display: inline-block
}

.gfm-fundraiser-card--size-default .hrt-media-tag {
    left: 1rem;
    position: absolute;
    top: -2.5rem
}

.gfm-fundraiser-card--size-default .gfm-goal-bar {
    margin-top: 1rem
}

.gfm-fundraiser-card--size-condensed {
    align-items: center;
    border-radius: 1rem;
    flex-direction: row
}

.gfm-fundraiser-card--size-condensed .gfm-fundraiser-card-image-container {
    aspect-ratio: 1/1;
    border-radius: .5rem;
    max-width: 5.5rem
}

.gfm-fundraiser-card--size-condensed .gfm-fundraiser-card-content {
    padding-left: 1rem
}

.gfm-fundraiser-card--size-condensed .gfm-fundraiser-card-body-text {
    display: none
}

.gfm-fundraiser-card--size-condensed .gfm-goal-bar {
    margin-top: .5rem
}

.gfm-fundraiser-card--size-condensed .hrt-media-tag {
    font-family: Rubik, system-ui, Trebuchet, Helvetica, Arial, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
    -webkit-backdrop-filter: unset;
    backdrop-filter: unset;
    background-color: initial;
    border: 0;
    padding: 0
}

.gfm-fundraiser-card--size-condensed-for-mobile {
    align-items: center;
    border-radius: 1rem;
    flex-direction: row
}

.gfm-fundraiser-card--size-condensed-for-mobile .gfm-fundraiser-card-image-container {
    aspect-ratio: 1/1;
    border-radius: .5rem;
    max-width: 5.5rem
}

.gfm-fundraiser-card--size-condensed-for-mobile .gfm-fundraiser-card-content {
    padding-left: 1rem
}

.gfm-fundraiser-card--size-condensed-for-mobile .gfm-fundraiser-card-body-text {
    display: none
}

.gfm-fundraiser-card--size-condensed-for-mobile .gfm-goal-bar {
    margin-top: .5rem
}

@media print,
screen and (min-width:48em) {
    .gfm-fundraiser-card--size-condensed-for-mobile {
        border-radius: 1.25rem;
        flex-direction: column
    }
    .gfm-fundraiser-card--size-condensed-for-mobile .gfm-fundraiser-card-image-container {
        aspect-ratio: 3/2;
        border-radius: 1rem;
        max-width: 100%
    }
    .gfm-fundraiser-card--size-condensed-for-mobile .gfm-fundraiser-card-content {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem
    }
    .gfm-fundraiser-card--size-condensed-for-mobile .gfm-fundraiser-card-body-text {
        display: inline-block
    }
    .gfm-fundraiser-card--size-condensed-for-mobile .hrt-media-tag {
        left: 1rem;
        position: absolute;
        top: -2.5rem
    }
    .gfm-fundraiser-card--size-condensed-for-mobile .gfm-goal-bar {
        margin-top: 1rem
    }
}

@media screen and (max-width:47.99875em) {
    .gfm-fundraiser-card--size-condensed-for-mobile .hrt-media-tag {
        font-family: Rubik, system-ui, Trebuchet, Helvetica, Arial, sans-serif;
        font-size: .875rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.5;
        -webkit-backdrop-filter: unset;
        backdrop-filter: unset;
        background-color: initial;
        border: 0;
        padding: 0
    }
}

.gfm-fundraiser-card--variant-default .gfm-fundraiser-card-body-text {
    color: #767676
}

.gfm-fundraiser-card--variant-default.sb-hover,
.gfm-fundraiser-card--variant-default:hover {
    border-color: #f1f1f1
}

.gfm-fundraiser-card--variant-default.sb-hover .gfm-fundraiser-card-body-text,
.gfm-fundraiser-card--variant-default:hover .gfm-fundraiser-card-body-text {
    color: #333
}

.gfm-fundraiser-card--variant-default.gfm-fundraiser-card--size-condensed .hrt-media-tag {
    color: #767676
}

.gfm-fundraiser-card--variant-default.gfm-fundraiser-card--size-condensed.sb-hover .hrt-media-tag,
.gfm-fundraiser-card--variant-default.gfm-fundraiser-card--size-condensed:hover .hrt-media-tag {
    color: #333
}

@media screen and (max-width:47.99875em) {
    .gfm-fundraiser-card--variant-default.gfm-fundraiser-card--size-condensed-for-mobile .hrt-media-tag {
        color: #767676
    }
    .gfm-fundraiser-card--variant-default.gfm-fundraiser-card--size-condensed-for-mobile.sb-hover .hrt-media-tag,
    .gfm-fundraiser-card--variant-default.gfm-fundraiser-card--size-condensed-for-mobile:hover .hrt-media-tag {
        color: #333
    }
}

a.gfm-fundraiser-card--variant-light {
    color: #fff
}

a.gfm-fundraiser-card--variant-light.sb-hover,
a.gfm-fundraiser-card--variant-light:hover {
    background-color: #0000004d;
    color: #fff
}

.gfm-fundraiser-card-content {
    position: relative;
    width: 100%
}

.gfm-fundraiser-card-content .gfm-goal-bar-label {
    cursor: inherit
}

.gfm-fundraiser-card-body-text {
    font-family: Rubik, system-ui, Trebuchet, Helvetica, Arial, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
    margin-bottom: 0;
    padding-top: .5rem;
    transition: color .5s cubic-bezier(.3, .01, 0, 1)
}

.gfm-fundraiser-card-image-container {
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%
}

.gfm-fundraiser-card-image {
    height: 100%;
    object-fit: cover;
    transition: transform .5s cubic-bezier(.3, .01, 0, 1);
    width: 100%;
    will-change: transform
}

.gfm-fundraiser-card-title {
    font-family: Rubik, system-ui, Trebuchet, Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0
}

.mobile-nav-header_headerContainer__dk48p {
    align-items: center;
    -webkit-backdrop-filter: saturate(170%) blur(3px);
    backdrop-filter: saturate(170%) blur(3px);
    background-color: #ffffffb3;
    display: flex;
    margin-bottom: 20px;
    padding: .5rem .75rem;
    position: sticky;
    top: 0;
    z-index: 3
}

.mobile-nav-header_leftSlot__qbJZb {
    align-items: center;
    display: flex;
    flex-grow: 1
}

.gfm-goal-bar {
    appearance: none;
    border-radius: 6.25rem;
    color: #02a95c;
    display: block;
    height: .5rem;
    overflow: hidden;
    width: 100%
}

.gfm-goal-bar::-webkit-progress-bar {
    border-radius: 6.25rem
}

.gfm-goal-bar::-webkit-progress-value {
    background: linear-gradient(90deg, #02c86d, #009852);
    border-radius: 6.25rem
}

.gfm-goal-bar::-moz-progress-bar {
    background: linear-gradient(90deg, #02c86d, #009852);
    border-radius: 6.25rem
}

.gfm-goal-bar::-ms-fill {
    background: linear-gradient(90deg, #02c86d, #009852);
    border-radius: 6.25rem
}

.gfm-goal-bar--default {
    background-color: #e4e4e4
}

.gfm-goal-bar--default::-webkit-progress-bar {
    background-color: #e4e4e4
}

.gfm-goal-bar--light {
    background-color: #0000004d
}

.gfm-goal-bar--light::-webkit-progress-bar {
    background-color: #0000004d
}

.gfm-goal-bar-label {
    font-family: Rubik, system-ui, Trebuchet, Helvetica, Arial, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
    display: block;
    font-weight: 700;
    margin-top: .375rem
}

.gfm-goal-bar-label--default {
    color: #333
}

.gfm-goal-bar-label--light {
    color: #fff
}

.sb-goal-bar-label {
    margin-bottom: .75rem
}

.sb-goal-bar-description,
.sb-goal-bar-label {
    font-family: Rubik, system-ui, Trebuchet, Helvetica, Arial, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
    color: #767676
}

.sb-goal-bar-description {
    margin-bottom: 0;
    margin-top: .75rem
}

.mobile-nav-list-item_navListItem__j3Z2K {
    padding: 1rem 2rem
}

.gfm-feature-icon {
    height: 3rem;
    width: 3rem
}

.range-input_rangeInput__C6KZK {
    align-items: center;
    display: flex;
    width: 100%
}

.range-input_track__YiPiT {
    background: #c8c8c8;
    height: .25rem;
    margin: 0 .5rem;
    width: 100%
}

.range-input_thumb__AV5uk {
    background: #767676;
    border-radius: 50%;
    height: 1rem;
    width: 1rem
}

.range-input_disableDoubletapToZoom__0dkVe {
    touch-action: none
}

.about_divider__YXq0g {
    color: #e4e4e4;
    margin: 1rem 2rem
}

.gfm-icon {
    color: inherit;
    display: inline-block;
    flex-shrink: 0;
    pointer-events: none;
    vertical-align: text-top
}

.photo-editor-center-modal_photoEditorModal__IdlVz.photo-editor-center-modal_isAspectRatioControlsEnabled__4aUT0 .photo-editor-controls {
    justify-content: space-between
}

.photo-editor-center-modal_photoEditorModal__IdlVz.photo-editor-center-modal_isAspectRatioControlsEnabled__4aUT0 .photo-editor-controls>div:first-of-type {
    border-bottom: none
}

@media screen and (max-width:63.99875em) {
    .photo-editor-center-modal_photoEditorModal__IdlVz.photo-editor-center-modal_isAspectRatioControlsEnabled__4aUT0 .photo-editor-controls>div:first-of-type {
        border-bottom: 1px solid #c8c8c8
    }
}

@media print,
screen and (min-width:64em) {
    .photo-editor-center-modal_photoEditorModal__IdlVz.photo-editor-center-modal_isAspectRatioControlsEnabled__4aUT0 .photo-editor-controls>div:first-of-type+div {
        width: 55%
    }
}

@media screen and (min-width:48em) {
    .photo-editor-center-modal_photoEditorModal__IdlVz.photo-editor-center-modal_isAspectRatioControlsEnabled__4aUT0.photo-editor-center-modal_isVerticalCropping__ZeFy0 .photo-editor-controls>div {
        border-bottom: 1px solid #c8c8c8
    }
    .photo-editor-center-modal_photoEditorModal__IdlVz.photo-editor-center-modal_isAspectRatioControlsEnabled__4aUT0.photo-editor-center-modal_isVerticalCropping__ZeFy0 .photo-editor-controls>div+div {
        width: 100%
    }
}

.photo-editor-center-modal_photoEditorModal__IdlVz.photo-editor-center-modal_isSmallModal__a52Ii .m-base-modal-inner {
    max-width: 29.6875rem
}

@media screen and (max-width:47.99875em) {
    .photo-editor-center-modal_photoEditorModal__IdlVz.photo-editor-center-modal_isSmallModal__a52Ii .m-base-modal-inner {
        height: 100vh;
        position: relative
    }
}

@media screen and (max-width:41.68625em) {
    .photo-editor-center-modal_photoEditorModal__IdlVz.photo-editor-center-modal_isSmallModal__a52Ii .m-base-modal-inner {
        height: auto;
        max-width: unset;
        position: fixed
    }
}

@media print,
screen and (min-width:64em) {
    .photo-editor-center-modal_photoEditorModal__IdlVz:not(.photo-editor-center-modal_isVerticalCropping__ZeFy0) .photo-editor-controls {
        flex-direction: row !important
    }
    .photo-editor-center-modal_photoEditorModal__IdlVz:not(.photo-editor-center-modal_isMediumModal__ZF_r2):not(.photo-editor-center-modal_isSmallModal__a52Ii) .m-base-modal-inner {
        max-width: 50rem
    }
}

.photo-editor-center-modal_photoEditorModal__IdlVz.photo-editor-center-modal_isSmallModal__a52Ii .photo-editor-center-modal_photoEditorModalInner___FNSr {
    max-width: 29.6875rem
}

@media screen and (max-width:41.68625em) {
    .photo-editor-center-modal_photoEditorModal__IdlVz.photo-editor-center-modal_isSmallModal__a52Ii .photo-editor-center-modal_photoEditorModalInner___FNSr {
        max-width: unset
    }
}

@media print,
screen and (min-width:64em) {
    .photo-editor-center-modal_photoEditorModal__IdlVz:not(.photo-editor-center-modal_isMediumModal__ZF_r2):not(.photo-editor-center-modal_isSmallModal__a52Ii) .photo-editor-center-modal_photoEditorModalInner___FNSr {
        max-width: unset
    }
}

.featured-carousel_hide-x-overflow__SinHt {
    overflow-x: hidden
}

.featured-carousel_homepageSection__QchX2 {
    margin-bottom: 3.5rem
}

@media print,
screen and (min-width:64em) {
    .featured-carousel_homepageSection__QchX2 {
        margin-bottom: 6.5rem
    }
}

.featured-carousel_homepageSectionExperiment__LSdGe {
    margin-bottom: 3.75rem
}

@media print,
screen and (min-width:64em) {
    .featured-carousel_homepageSectionExperiment__LSdGe {
        margin-bottom: 6rem
    }
}

.featured-carousel_heading__f3kl7 {
    margin: 0 auto
}

.featured-carousel_subheading__o0oVq {
    margin-top: .5rem
}

.featured-carousel_grid-columns__PC_cS {
    grid-gap: 2rem
}

.featured-carousel_heading-1__NBgFQ {
    font-size: 2.5rem;
    line-height: 1.1
}

.featured-carousel_carousel___MMN4 {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none
}

.featured-carousel_carousel___MMN4::-webkit-scrollbar {
    display: none
}

.featured-carousel_carousel-item__oZnoV {
    scroll-snap-align: start;
    scroll-snap-stop: always
}

.featured-carousel_progress-dots__jC81m {
    display: flex;
    flex-flow: row wrap;
    gap: .375rem;
    justify-content: flex-start;
    position: relative
}

.featured-carousel_progress-dots__jC81m:after,
.featured-carousel_progress-dots__jC81m:before {
    background-color: #333;
    border-radius: .1875rem;
    content: "";
    display: inline-flex;
    height: .375rem;
    margin-left: 0;
    position: absolute;
    width: 1.125rem;
    z-index: 2
}

@media(prefers-reduced-motion:no-preference) {
    .featured-carousel_progress-dots__jC81m:after {
        transition: margin-left .1s ease-out
    }
    .featured-carousel_progress-dots__jC81m:before {
        transition: margin-left .1s ease-out .2s
    }
}

.featured-carousel_progress-dots__jC81m[data-position="0"]:after,
.featured-carousel_progress-dots__jC81m[data-position="0"]:before {
    margin-left: 0
}

.featured-carousel_progress-dots__jC81m[data-position="1"]:after,
.featured-carousel_progress-dots__jC81m[data-position="1"]:before {
    margin-left: .75rem
}

.featured-carousel_progress-dots__jC81m[data-position="2"]:after,
.featured-carousel_progress-dots__jC81m[data-position="2"]:before {
    margin-left: 1.5rem
}

.featured-carousel_progress-dots__jC81m[data-position="3"]:after,
.featured-carousel_progress-dots__jC81m[data-position="3"]:before {
    margin-left: 2.25rem
}

.featured-carousel_progress-dots-item__jcMTw {
    background-color: #3333331a;
    border-radius: .1875rem;
    display: inline-flex;
    height: .375rem;
    transition: margin-left .3s ease-in-out;
    width: .375rem;
    z-index: 1
}

.featured-carousel_carousel-section__V_YA_ {
    position: relative;
    width: 100%
}

.featured-carousel_carousel-container__Yfpf1 {
    display: flex;
    flex-flow: row nowrap;
    gap: .5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    scroll-padding: 1.5rem
}

.featured-carousel_carousel-dots__QzfgN {
    bottom: .75rem;
    left: 2.5rem;
    position: absolute
}

.featured-carousel_carousel-card__ZJ_t6 {
    flex: 0 0 100%;
    width: 0
}

.featured-carousel_carousel-card-link__vxDaY {
    background-color: #f8f8f8;
    border-radius: .75rem;
    display: grid;
    grid-template-columns: 3fr 2fr;
    height: 100%;
    position: relative;
    text-decoration: none;
    width: 100%
}

.featured-carousel_carousel-card-title__6qd7Q {
    font-family: Rubik, system-ui, Trebuchet, Helvetica, Arial, sans-serif;
    font-size: clamp(1rem, 1rem + .25*(100vw - 23.4375rem)/66.5625, 1.25rem);
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
    padding: 1rem
}

.featured-carousel_carousel-card-image__EKVaT {
    align-self: stretch;
    aspect-ratio: 1/1;
    border-radius: .75rem;
    justify-self: end;
    max-height: 160px;
    object-fit: cover;
    padding: .25rem
}

.photo-editor_photoEditor__Qyk0P {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto
}

.photo-editor_loader___Pqtl {
    background-color: #fff;
    height: 100vh;
    min-height: 33.75rem
}

.photo-editor_loaderImage__sxdGW {
    height: 2.5rem;
    width: 2.5rem
}

.photo-editor_header__l0KsE {
    color: #333;
    justify-content: space-between
}

.photo-editor_headerCloseButton__L_KqJ {
    font-size: 1.25rem
}

.photo-editor_headerTitle__zxJUU {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

.photo-editor_content__f7w9Q {
    background-color: #000;
    flex-grow: 1;
    overflow: hidden
}

.photo-editor_alert__af_0U {
    top: 4.5rem
}

@media print,
screen and (min-width:64em) {
    .photo-editor_alert__af_0U {
        top: 5.75rem
    }
}

.photo-editor_cropArea__I_QJi {
    border: 1px solid #ddd;
    border-radius: .25rem;
    pointer-events: none;
    position: absolute
}

.photo-editor_control__K5Oet {
    border-right: 1px solid #c8c8c8;
    padding: .5rem
}

@media screen and (min-width:48em) {
    .photo-editor_control__K5Oet {
        padding-left: 1rem;
        padding-right: 1rem
    }
}

.photo-editor_footer__n76wl {
    background-color: #fff;
    width: 100%
}

.photo-editor_rotateIcon__kiOlH {
    transform: rotateY(180deg)
}

.photo-editor_aspectRatioControls__O_UfH {
    border-bottom: 1px solid #c8c8c8
}

.photo-editor_photoEditorWithAspectOptions__Ha2hM .photo-editor_editPhotoControls__RLCZT {
    flex-direction: column
}

.category-carousel_title__H2q6l {
    font-family: Rubik, system-ui, Trebuchet, Helvetica, Arial, sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.2;
    display: block;
    padding: 1rem 2rem
}

.category-carousel_categoryCarousel__fCReZ {
    display: flex;
    gap: .5rem;
    -ms-overflow-style: none;
    overflow-x: scroll;
    padding-bottom: 1rem;
    scroll-padding: .5rem;
    scroll-snap-type: x;
    scrollbar-width: none
}

.category-carousel_categoryCarousel__fCReZ::-webkit-scrollbar {
    display: none
}

.category-carousel_carouselBlock__mhuuC {
    scroll-snap-align: start
}

.category-carousel_carouselBlock__mhuuC:first-child {
    padding-left: 2rem
}

.category-carousel_carouselBlock__mhuuC:last-child {
    padding-right: 2rem
}

.category-carousel_carouselBlockIcon__bH6MJ {
    align-items: center;
    background-color: #f8f8f8;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    min-height: 6rem;
    min-width: 6rem
}

.category-carousel_featureIcon__hviXY {
    height: 3.75rem;
    width: 3.75rem
}

.category-carousel_carouselBlockLabel__r_mQy {
    font-family: Rubik, system-ui, Trebuchet, Helvetica, Arial, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
    padding-left: .5rem;
    padding-top: .25rem
}

.photo-drop-zone_photoEditorModal__AaK_f {
    border-radius: .5rem !important;
    overflow: hidden
}

@media screen and (max-width:47.99875em) {
    .photo-drop-zone_photoEditorModal__AaK_f {
        border-radius: 0 !important
    }
}

.profile_addButton__Z89BG {
    background-color: #f8f8f8;
    border: 1px dashed #c8c8c8;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%
}

.profile_addButton__Z89BG:hover {
    background-color: #f8f8f8
}

.profile_addPhotoField__vNG3M {
    display: flex;
    flex-shrink: 0;
    height: 5rem;
    justify-content: space-around;
    position: relative;
    width: 5rem
}

.profile_profileContainer__bostC {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem
}

.profile_profileImage__Bi325 {
    border-radius: 50%;
    height: 5rem;
    object-fit: cover;
    width: 5rem
}

.profile_profileButton__H_Uga {
    align-items: center;
    display: flex;
    gap: .25rem;
    padding: .375rem .5rem 0
}

.recent-activity_dot__jtZVi {
    font-weight: 900;
    padding: 0 .5rem
}

.recent-activity_fundActivityContainer__RuOWh {
    color: #767676;
    grid-area: activity;
    margin-left: .5rem
}

.recent-activity_fundContainer__xrBwz {
    display: grid;
    grid-template-areas: "image title" "image activity";
    grid-template-columns: 2.5rem auto;
    grid-template-rows: auto
}

.recent-activity_fundContainer__xrBwz:hover {
    background-color: #f8f8f8
}

.recent-activity_fundImage__x_5Hu {
    border-radius: .25rem;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    width: 100%
}

.recent-activity_fundImageContainer__q08T4 {
    grid-area: image;
    height: 2.5rem;
    position: relative;
    width: 2.5rem
}

.recent-activity_fundListItem__BafnF {
    font-size: .875rem;
    font-weight: 400
}

.recent-activity_fundListItem__BafnF:not(:first-of-type) {
    margin-top: .5rem
}

.recent-activity_fundTitleContainer__okXNF {
    color: #333;
    grid-area: title;
    margin-left: .5rem
}

.recent-activity_recentActivityList__DWhlw {
    padding: 0 2rem 1rem
}

.main_buttons__bAoVH {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem
}

.menu_menuContentContainer__qrWhF {
    display: grid;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    width: 100%
}

.menu_menuContentContainer__qrWhF::-webkit-scrollbar {
    display: none
}

.menu_noHeight__HBo6h {
    display: block;
    height: 0;
    line-height: 0;
    overflow: hidden
}

.menu_menuContent__xYnGh {
    transition: transform .2s, visibility .2s
}

.menu_menuContentActive__Krxwr {
    grid-column: 1;
    grid-row: 1;
    padding-bottom: .5rem;
    transform: translateX(0);
    visibility: visible
}

.menu_menuContentSlideLeft__qBshk {
    grid-column: 1;
    grid-row: 1;
    transform: translateX(-100vw);
    visibility: hidden
}

.menu_menuContentSlideRight__azTCP {
    grid-column: 1;
    grid-row: 1;
    transform: translateX(100vw);
    visibility: hidden
}

.menu_sideSheet__5_voz {
    border-radius: 0
}

.menu_sideSheet__5_voz>div {
    height: 100%;
    padding: 0
}

.default-header_shared-default-header__Nebuq [data-element-id=btn_nav_search_icon] {
    font-size: 1rem !important
}

.default-header_shared-default-header-individuals-dropdown__hqpAQ {
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: repeat(4, minmax(0, 1fr))
}

.default-header_shared-default-header-person__gbs0b {
    align-self: center
}

.default-header_shared-default-header-sign-up-button__xQ4v9 {
    border-radius: 624.9375rem;
    margin-left: .5rem;
    padding: .25rem 1rem
}

.default-header_shared-default-header-sign-up-button__xQ4v9[class*=hrt-primary-button] {
    background-color: #008748;
    border-color: #0000;
    color: #fff
}

.default-header_shared-default-header-sign-up-button__xQ4v9[class*=hrt-primary-button]:hover {
    background-color: #006e3a;
    color: #fff
}

/*# sourceMappingURL=e210d6d6ef1946b8.css.map*/