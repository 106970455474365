/** Variables to insert into the remainder of the stylesheet */

* {
    --primary-color: #02a95c;
    --text-color: #333333;
}

/** The wrapper around the entire collapsed hovering consent icon */

.collapsed-container {
    position: fixed;
    bottom: 100px;
    right: 5px;
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: none;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.95);
    overflow: hidden;
    cursor: pointer;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: CircularXXWeb, avenir next, avenir, helvetica neue, helvetica,
        "sans-serif";
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px) saturate(5);
    transition: width 150ms ease-in 500ms, background-color 150ms ease-in-out,
        box-shadow 150ms ease-in-out;
}

/** Buttons displayed throughout the consent UI */

.button {
    border-radius: 12px;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    box-sizing: border-box;
    cursor: pointer;
    font-family: inherit;
    text-align: center;
    padding: 8px 24px;
    margin-top: 8px;
}

/** On button hover */

.button:hover {
    background-color: #f8f8f8;
}

/** Base styles applied to button text */

.button-base-text {
    color: var(--text-color);
    display: block;
    font-style: normal;
    text-align: center;
}

/** Styles applied to "primary" button text */

.button-primary-text {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1;
}

/** Modal title text styles */

.text-title {
    font-size: clamp(1rem, 1rem + 0.25 * (100vw - 23.4375rem)/66.5625, 1.25rem);
    font-weight: 600;
    color: var(--text-color);
    margin: 0 32px 8px 0;
    text-align: left;
    line-height: 1.2;
}

/** Paragraph text styles */

.paragraph {
    color: var(--text-color);
    font-size: 14px;
    margin: 0 0 8px 0;
    line-height: 1.2;
}

/** Paragraph link text styles */

.paragraph a {
    color: var(--text-color);
}

/** Styles applied to the main contents of the consent modal (does not include footer) */

.column-content {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    animation: fadeIn 150ms;
}

/** Button row styles for AcceptOrRejectAll view state */

.accept-or-reject-all-button-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
}

/** Styles applied to bottom menu */

.bottom-menu-container {
    width: calc(100% - 20px);
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

/** Menu item styles for the BottomMenu */

.bottom-menu-item {
    background: unset;
    border: unset;
    width: unset;
    padding: unset;
    margin: unset;
    margin: 0 16px;
    display: block;
    min-width: fit-content;
    width: auto;
    text-align: center;
    line-height: 1.5;
    font-family: inherit;
    font-size: 10px;
    font-weight: 400;
    color: var(--text-color);
    transition: text-decoration 150ms;
    text-decoration: underline;
}

/** Hover state for items in the bottom menu */

.bottom-menu-item:hover {
    cursor: pointer;
    text-decoration: none;
}

/** Container that is wrapped around the modal */

.modal-container {
    position: fixed;
    bottom: 10px;
    left: 5px;
    right: 5px;
    margin: 0 auto;
    width: calc(100% - 32px);
    box-sizing: border-box;
    border-radius: 16px;
    border: none;
    background-color: #ffffff;
    overflow: hidden;
    padding: 21px;
    font-family: CircularXXWeb, avenir next, avenir, helvetica neue, helvetica,
        "sans-serif";
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px) saturate(5);
    animation: fadeIn 150ms;
    max-height: 90%;
    transition: width 150ms, height 150ms;
}

/** Inner contents wrapper for modal content */

.modal-container-inner {
    height: calc(100% - 38px);
    width: 100%;
    padding-bottom: 38px;
}

/** Styles for button of X marker in top right corner on hover */

.do-not-sell-explainer-close:hover,
.do-not-sell-explainer-close:focus {
    background-color: #ffffff;
}

/** Styles for button of X marker in top right corner on hover */

.privacy-policy-notice-with-close-button-close:hover,
.privacy-policy-notice-with-close-button-close:focus {
    background-color: #ffffff;
}

/** Container for rendering checkbox toggles */

.toggles-container {
    height: 100%;
    display: flex;
    margin: 20px;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

/** Do not sell switch interface */

.margin-tops > * {
    margin-top: 4px;
}

/** Styles that wrap the text container of the modal content. Excludes footer */

.inner-container {
    overflow-y: auto;
    padding: 2px;
    margin: -2px;
}

/** Outline for do not sell explainer */

.do-not-sell-explainer-interface {
    margin-top: 1em;
}

/** Paragraph within do not sell explainer */

.do-not-sell-explainer-interface > .paragraph {
    margin-bottom: 0;
}

/** Styles applied to "secondary" button text */

.button-secondary-text {
    font-size: 9px;
    font-weight: 400;
}

/** Screen reader styles */

.screen-reader {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

/** Styles applied to `CompleteOptions` view state body  */

.complete-options-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
}

/** Container around `CompleteOptions` view state */

.complete-options-container {
    height: inherit;
    animation: fadeIn 200ms ease-in;
}

/** Style applied to each label text for each checkbox toggle and switch */

.toggle-label {
    position: relative;
    margin: 0 10px 15px 10px;
    font-size: 12px;
    font-weight: 500;
    height: 16px;
    vertical-align: baseline;
    color: var(--text-color);
    text-shadow: none;
    padding-left: 25px;
    word-wrap: break-word;
}

/** Style applied to the checkbox toggle box */

.toggle-label input:focus + span {
    outline: 2px solid rgba(51, 157, 255, 0.7);
    outline-offset: 1px;
}

/** Style applied to create custom checkmark */

.toggle-checkmark {
    display: inline-block;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 5px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #d8d8d8;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 150ms ease, background 300ms ease;
    cursor: pointer;
}

/** Style applied to create custom checkmark ::after */

.toggle-checkmark::after {
    opacity: 0;
    transform-origin: bottom center;
    transform: rotate(45deg) scale(0);
    content: "";
    position: absolute;
    left: 1px;
    top: 0;
    width: 3px;
    height: 8px;
    border: solid #ffffff;
    border-width: 0px 2px 2px 0px;
    transition: all 150ms cubic-bezier(0.41, 0.94, 0.71, 1.41),
        opacity 150ms ease;
}

/** Style applied to checkbox input */

.toggle-input {
    position: absolute;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

/** Style applied to checkbox input when disabled */

.toggle-input:disabled {
    cursor: not-allowed;
}

/** Style applied to checkbox input span when checked */

.toggle-input:checked + span {
    background: var(--primary-color);
    border-color: transparent;
}

/** Style applied to checkbox input when checked and hovering */

.toggle-input:checked + span,
.toggle-input:hover + span {
    filter: brightness(0.9);
}

/** Style applied to checkbox input when checked and after */

.toggle-input:checked + span::after {
    opacity: 1;
    transform: rotate(45deg) scale(1);
}

/** Style applied to toggle input when focused */

.toggle-input:hover:enabled + span,
.toggle-input:focus-visible + span {
    border-color: var(--primary-color);
}

/** Style applied to checkbox input span when disabled */

.toggle-input:disabled + span {
    background-color: #ececec;
    cursor: not-allowed;
}

/** Style applied to checkbox input span when disabled and hover */

.toggle-input:disabled + span,
.toggle-input:hover + span {
    filter: none;
    border-color: transparent;
}

/** Style applied to checkbox input span when disabled */

.toggle-input:disabled + span::after {
    border-color: #d8d8d8;
}

/** Styles applied around the language container */

.language-options-container {
    width: 100%;
    height: 100%;
    animation: fadeIn 200ms ease-in;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    justify-content: space-evenly;
}

/** Styles applied around each language selection */

.language-item-container {
    padding: 12px;
}

/** Styles applied to the language button */

.language-button {
    background: unset;
    border: unset;
    width: unset;
    padding: unset;
    margin: unset;
    width: 17px;
    height: 17px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

/** Styles to apply when language svg select button is hovered on */

.language-button-inactive svg {
    fill: var(--text-color);
    transition: fill 150ms;
}

/** Styles to apply when actively labeled */

.language-button-active svg {
    fill: var(--primary-color);
    transition: fill 150ms;
}

/** Styles to apply when language svg select button is hovered on */

.language-button svg:hover {
    fill: var(--primary-color);
}

/** Styles to apply when language svg select button is active */

.language-button svg:active {
    fill: var(--primary-color);
}

/** Outer styles wrapped around entire bottom menu */

.footer-container {
    position: absolute;
    bottom: 21px;
    width: calc(100% - 21px * 2);
    height: 17px;
}

/** Styles applied to each item of bottom menu */

.bottom-menu-item-container {
    width: auto;
}

/** Styles for the collapsed square logo  */

.collapsed-square-logo-svg {
    width: 20px;
    height: 20px;
    padding: 6px;
    flex-shrink: 0;
}

/** Styles for the collapsed square logo path */

.collapsed-square-logo-svg path {
    fill: var(--text-color);
    transition: fill 150ms;
}

/** Styles applied to consent icon when actively selected */

.collapsed-container:active {
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0px 0.5px 5px 1.5px rgba(0, 0, 0, 0.12);
}

/** Styles applied to consent icon on hover */

.collapsed-container:hover {
    width: 132px;
    transition-delay: 0ms;
}

/** Styles applied to consent icon SVG contents */

.collapsed-container:hover path {
    fill: var(--primary-color);
}

/** Styles applied to consent icon paragraph text */

.collapsed-container p {
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    color: var(--text-color);
    opacity: 0;
    transition: opacity 150ms ease-in 500ms;
}

/** Styles applied to consent icon paragraph text on hover */

.collapsed-container:hover p {
    opacity: 1;
    transition-delay: 0ms;
}

/** Animation for fading in, used throughout the stylesheet */

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/** Styles for button of X marker in top right corner */

.do-not-sell-explainer-close {
    --squareDimension: 25px;
    padding: 4px;
    position: absolute;
    top: 21px;
    right: 21px;
    border: none;
    cursor: pointer;
    background-color: #f2f2f2;
    border-radius: 5px;
    height: var(--squareDimension);
    width: var(--squareDimension);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 150ms;
}

/** Styles for button of X marker in top right corner */

.accept-all-reject-all-toggle-close {
    --squareDimension: 25px;
    padding: 4px;
    position: absolute;
    top: 21px;
    right: 21px;
    border: none;
    cursor: pointer;
    background-color: #f2f2f2;
    border-radius: 5px;
    height: var(--squareDimension);
    width: var(--squareDimension);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 150ms;
}

/** Styles for button of X marker in top right corner on hover */

.accept-all-reject-all-toggle-close:hover,
.accept-all-reject-all-toggle-close:focus {
    background-color: #e2e2e2;
}

/** Styles for button of X marker in top right corner */

.privacy-policy-notice-with-close-button-close {
    --squareDimension: 25px;
    padding: 4px;
    position: absolute;
    top: 21px;
    right: 21px;
    border: none;
    cursor: pointer;
    background-color: #f2f2f2;
    border-radius: 5px;
    height: var(--squareDimension);
    width: var(--squareDimension);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 150ms;
}

/** Styles for button of X marker in top right corner */

.complete-options-toggle-close {
    --squareDimension: 25px;
    padding: 4px;
    position: absolute;
    top: 21px;
    right: 21px;
    border: none;
    cursor: pointer;
    background-color: #f2f2f2;
    border-radius: 5px;
    height: var(--squareDimension);
    width: var(--squareDimension);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 150ms;
}

/** Styles for button of X marker in top right corner on hover */

.complete-options-toggle-close:hover,
.complete-options-toggle-close:focus {
    background-color: #e2e2e2;
}

/** Outline for do not sell explainer */

.complete-options-toggle-interface {
    margin-top: 2em;
}

/** Styles for complete options toggle description */

.complete-options-toggle-description {
    padding-bottom: 2em;
}

/** GPC outer styles */

.gpc-setting {
    display: flex;
    align-items: center;
}

/** GPC svg styles */

.gpc-setting svg {
    flex-shrink: 0;
}

/** GPC paragraph text */

.gpc-setting p {
    margin: 0 0 0 6px;
    font-size: 14px;
    color: var(--text-color);
}

/** Styles for the switch */

.switch {
    --switchHeight: 20px;
    --switchGutter: 2px;
    --switchButtonDiameter: calc(var(--switchHeight) - var(--switchGutter) * 2);
}

/** The label that wraps the switch */

.switch.label {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    height: 16px;
    vertical-align: baseline;
    text-shadow: none;
    white-space: nowrap;
    padding: 0 5px;
}

/** Switch label */

.switch.switch-background {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 0.8em;
    cursor: pointer;
    width: calc(var(--switchHeight) * 2 - 2px);
    height: var(--switchHeight);
    background: #7e8793;
    border-radius: var(--switchHeight);
    position: relative;
    transition: background-color 150ms;
}

/** Button for switch */

.switch.switch-background .switch.switch-button {
    content: "";
    position: absolute;
    top: var(--switchGutter);
    left: var(--switchGutter);
    width: var(--switchButtonDiameter);
    height: var(--switchButtonDiameter);
    border-radius: var(--switchButtonDiameter);
    transition: 150ms;
    background: #fff;
}

/** When switch is checked */

.switch.switch-checkbox:checked
    + .switch.switch-background
    .switch.switch-button {
    left: calc(100% - var(--switchGutter));
    transform: translateX(-100%);
}

/** When switch background is checked */

.switch.switch-checkbox:checked + .switch.switch-background {
    background-color: var(--primary-color);
}

/** When switch is focused */

.switch.switch-checkbox:focus
    + .switch.switch-background
    .switch.switch-button {
    outline: 2px solid rgba(51, 157, 255, 0.7);
    outline-offset: 1px;
    outline-width: 5px;
}

/** Switch button when active */

.switch.switch-background:active .switch.switch-button {
    width: calc(var(--switchButtonDiameter) * 1.2);
}

/** Container around the Transcend logo in the bottom left hand side of the modal */

.transcend-logo-container {
    width: 17px;
    height: 17px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
}

/** Styles applied to the Transcend svg logo in the bottom left hand side of the modal */

.transcend-logo-svg {
    width: 64px;
    height: 17px;
}

/** Styles applied to the Transcend svg logo path in the bottom left hand side of the modal */

.transcend-logo-svg path {
    fill: var(--text-color);
    transition: fill 150ms;
}

/** Styles applied to the Transcend svg wordmark in the bottom left hand side of the modal */

.transcend-logo-svg .transcend-wordmark {
    fill-opacity: 0;
    transition: fill 150ms, fill-opacity 150ms;
}

/** At least tablet */

@media (min-width: 640px) {
    .button {
        margin-top: 0;
        max-width: 320px;
        min-height: 48px;
    }
    .button-primary-text {
        font-size: 16px;
    }
    .paragraph {
        margin: 8px 18px 0 0;
    }
    .column-content {
        flex-direction: row;
        align-items: center;
    }
    .accept-or-reject-all-button-row {
        flex-direction: row;
        flex-wrap: wrap;
        width: auto;
        min-width: 55%;
    }
    .bottom-menu-container {
        width: calc(100% - 150px);
        justify-content: center;
    }
    .bottom-menu-item {
        font-size: 12px;
    }
    .modal-container {
        bottom: 5px;
        max-width: 768px;
        bottom: 22px;
    }
    .toggles-container {
        flex-direction: row;
    }
    .accept-or-reject-all-button-row button:nth-of-type(1) {
        width: calc(50% - 4px);
    }
    .accept-or-reject-all-button-row button:nth-of-type(2) {
        width: calc(50% - 4px);
        margin-left: 8px;
    }
    .accept-or-reject-all-button-row button:nth-of-type(3) {
        margin-top: 8px;
        max-width: 420px;
    }
    .transcend-logo-container {
        width: 64px;
    }
    .text-title-left {
        margin: 0 18px 0 0;
        text-align: left;
    }
    .text-title-center {
        margin: 0 0 18px 0;
        text-align: center;
    }
    .transcend-logo-svg:hover .transcend-wordmark,
    .transcend-logo-container:focus .transcend-wordmark {
        fill-opacity: 1;
    }
    .transcend-logo-svg:hover path,
    .transcend-logo-container:focus path {
        fill: var(--primary-color);
    }
}
