.campaign-header-navigation_navCampaignPageLogo__CM8HZ {
    align-items: center;
    display: flex;
    height: 2.375rem;
    justify-content: center;
    width: 6.5625rem;
}

.locale-picker_localePickerButton__WsmkM {
    border-radius: 0.75rem;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    padding: 0.5rem !important;
}

@media screen and (max-width: 47.99875em) {
    .locale-picker_localePickerButton__WsmkM {
        width: 100%;
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-more-resources {
    margin-top: -1rem;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
}

@media screen and (min-width: 48em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-more-resources {
        grid-column: 1 / span 4;
    }
}

.default-footer_defaultFooter__ISvRD
    .shared-default-footer-more-resources--open {
    opacity: 1;
}

.default-footer_defaultFooter__ISvRD
    .shared-default-footer-more-resources-button {
    font-weight: 600;
    grid-column-start: 1;
    justify-self: start;
    margin-left: -0.5rem;
}

@media screen and (min-width: 48em) {
    .default-footer_defaultFooter__ISvRD
        .shared-default-footer-more-resources-button {
        margin-top: 2rem;
    }
}

.default-footer_defaultFooter__ISvRD
    .shared-default-footer-more-resources-list {
    margin: 0;
    padding-left: 0;
}

@media screen and (min-width: 48em) {
    .default-footer_defaultFooter__ISvRD
        .shared-default-footer-more-resources-list {
        column-count: 4;
    }
}

.default-footer_defaultFooter__ISvRD
    .shared-default-footer-secondary--with-app-stores {
    grid-template-areas: "locale" "social" "stores" "legal" !important;
}

@media screen and (max-width: 47.99875em) {
    .default-footer_defaultFooter__ISvRD
        .shared-default-footer-secondary--with-app-stores {
        grid-gap: 1.5rem !important;
    }
}

@media print, screen and (min-width: 48em) {
    .default-footer_defaultFooter__ISvRD
        .shared-default-footer-secondary--with-app-stores {
        grid-template-areas: "locale social" "legal stores" !important;
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-secondary-icons {
    margin-left: -0.5rem;
}

@media screen and (min-width: 48em) {
    .default-footer_defaultFooter__ISvRD
        .shared-default-footer-secondary-icons {
        margin-left: 0;
        margin-right: -0.5rem;
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-locale {
    grid-area: locale;
}

@media screen and (max-width: 47.99875em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-legal-date {
        margin-bottom: 0.5rem;
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-stores {
    align-items: center;
    display: flex;
    grid-area: stores;
}

@media print, screen and (min-width: 64em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-stores {
        justify-content: flex-end;
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-store-button {
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 2.125rem;
    text-indent: -9999px;
}

.default-footer_defaultFooter__ISvRD
    .shared-default-footer-store-button--apple {
    height: 2.125rem;
    width: 6.375rem;
}

.default-footer_defaultFooter__ISvRD
    .shared-default-footer-store-button--google {
    height: 2.125rem;
    margin-right: 0.5rem;
    width: 7.3125rem;
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-store-button--fr {
    width: 6.75rem;
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-legal-list {
    flex-wrap: wrap;
    margin-left: -0.5rem;
}

@media print, screen and (min-width: 48em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-legal-item {
        margin-right: 0.5rem;
    }
}

@media screen and (min-width: 48em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-multicolumn {
        grid-column: 3 / span 2;
    }
    .default-footer_defaultFooter__ISvRD
        .shared-default-footer-multicolumn
        > ul {
        column-count: 2;
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-list-header {
    margin-bottom: 0.25rem;
    margin-top: 0.5rem;
}

@media screen and (min-width: 48em) {
    .default-footer_defaultFooter__ISvRD .shared-default-footer-list-header {
        margin-top: 0;
    }
}

.default-footer_defaultFooter__ISvRD .shared-default-footer-listitem {
    margin-left: -0.5rem;
    padding: 0.5rem;
}

@media screen and (max-width: 63.99875em) {
    .page-campaign-layout_footer__KTsOX {
        padding-bottom: 3rem;
    }
}

.page-campaign-layout_campaignPageLayout__ZK9_s .t-campaign-page-header,
.page-campaign-layout_campaignPageLayout__ZK9_s .t-campaign-page-template-top {
    transition: all 0.5s ease;
}

.page-campaign-layout_campaignPageLayout__ZK9_s .t-campaign-page-template-top {
    background-color: #fff;
    position: relative;
    z-index: 100;
}

.page-campaign-layout_campaignPageLayout__ZK9_s
    .t-campaign-page-template-alert {
    position: sticky;
    top: 0;
    z-index: 10;
}

@media print, screen and (min-width: 64em) {
    .page-campaign-layout_campaignPageLayout__ZK9_s
        .t-campaign-page-template-content {
        margin-top: 2rem !important;
    }
    .page-campaign-layout_campaignPageLayout__ZK9_s
        .t-campaign-page-template--stats:not(
            .t-campaign-page-template--stats-hide-widget
        ) {
        width: max(75%, 100vw - 390px);
    }
    .page-campaign-layout_campaignPageLayout__ZK9_s
        .t-campaign-page-stats-widget {
        background: #fbf8f6;
        box-shadow: 0 0 5px #ddd;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 100;
    }
    .page-campaign-layout_campaignPageLayout__ZK9_s
        .t-campaign-page-stats-widget:not(
            .t-campaign-page-stats-widget-hide-widget
        ) {
        display: inline-flex;
        flex-direction: column;
        height: 100vh;
        max-width: 390px;
        padding: 1rem;
        width: 25%;
    }
}

@media (orientation: portrait) {
    body[data-dim-body]
        .page-campaign-layout_campaignPageLayout__ZK9_s
        .m-campaign-sidebar-notification,
    body[data-dim-body]
        .page-campaign-layout_campaignPageLayout__ZK9_s
        .m-progress-meter-heading,
    body[data-dim-body]
        .page-campaign-layout_campaignPageLayout__ZK9_s
        .p-campaign-byline,
    body[data-dim-body]
        .page-campaign-layout_campaignPageLayout__ZK9_s
        .p-campaign-description,
    body[data-dim-body]
        .page-campaign-layout_campaignPageLayout__ZK9_s
        .p-campaign-title,
    body[data-dim-body]
        .page-campaign-layout_campaignPageLayout__ZK9_s
        .t-campaign-page-header,
    body[data-dim-body]
        .page-campaign-layout_campaignPageLayout__ZK9_s
        .t-campaign-page-template-top,
    body[data-dim-body]
        .page-campaign-layout_campaignPageLayout__ZK9_s
        progress {
        opacity: 0;
    }
    body[data-dim-body]
        .page-campaign-layout_campaignPageLayout__ZK9_s
        .m-sticky-cta-inner,
    body[data-dim-body]
        .page-campaign-layout_campaignPageLayout__ZK9_s
        .t-campaign-page-header,
    body[data-dim-body]
        .page-campaign-layout_campaignPageLayout__ZK9_s
        .t-campaign-page-template-top {
        background-color: #000;
    }
    body[data-dim-body]
        .page-campaign-layout_campaignPageLayout__ZK9_s
        .cta-clips-gray {
        background: #333;
        color: #fff;
    }
    body[data-dim-body]
        .page-campaign-layout_campaignPageLayout__ZK9_s
        .o-campaign-story {
        color: #000;
    }
}

.peek-modal_baseModal__91SEp .m-base-modal-inner {
    animation: peek-modal_slideOutModal__uI1JR 0.3s ease-out !important;
    background-color: #fff;
    padding: 2rem 1.5rem;
}

@media (prefers-reduced-motion: reduce) {
    .peek-modal_baseModal__91SEp .m-base-modal-inner {
        animation: peek-modal_fadeOut__3yQ4Q 0s ease-out forwards !important;
    }
}

.peek-modal_baseModal__91SEp .m-base-modal-inner--open {
    animation: peek-modal_slideInModal__xfuSS 0.3s ease-out forwards !important;
}

@media (prefers-reduced-motion: reduce) {
    .peek-modal_baseModal__91SEp .m-base-modal-inner--open {
        animation: peek-modal_fadeIn__rli__ 0s ease-out forwards !important;
    }
}

@media screen and (max-width: 47.99875em) {
    .peek-modal_baseModal__91SEp,
    .peek-modal_baseModal__91SEp .m-base-modal-inner {
        bottom: 0;
        left: unset;
        position: absolute;
        right: unset;
        top: unset;
    }
    .peek-modal_baseModal__91SEp .m-base-modal-inner {
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0.75rem;
    }
}

@media print, screen and (min-width: 48em) {
    .peek-modal_baseModal__91SEp .m-base-modal-inner {
        border-radius: 0.5rem;
        box-shadow: 0 0.3125rem 1rem -0.1875rem #00000080;
        margin: auto;
        max-height: 80vh;
        width: 22.8125rem;
    }
}

@media print, screen and (min-width: 48em) and (min-height: 50rem) {
    .peek-modal_baseModal__91SEp .m-base-modal-inner {
        max-height: 50rem;
    }
}

@keyframes peek-modal_slideInModal__xfuSS {
    0% {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes peek-modal_slideOutModal__uI1JR {
    0% {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}

@keyframes peek-modal_fadeIn__rli__ {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes peek-modal_fadeOut__3yQ4Q {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.peek-modal_exitButton__6PZQc {
    justify-content: flex-end;
    margin-left: auto;
}

@media print, screen and (min-width: 48em) {
    .ggg-interstitial-modal_modal__yx0bK .m-base-modal-inner {
        padding-left: 3rem;
        padding-right: 3rem;
        width: 35rem;
    }
}

.ggg-interstitial-modal_headline__R3Cx7 {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
}

@media print, screen and (min-width: 48em) {
    .ggg-interstitial-modal_headline__R3Cx7 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.ggg-interstitial-modal_listItem__iHUhG {
    display: flex;
    padding: 0.5rem 0;
}

@media print, screen and (min-width: 48em) {
    .ggg-interstitial-modal_listItem__iHUhG {
        padding: 0.5rem 1.5rem;
    }
}

.ggg-interstitial-modal_hr__LG_3z {
    border-top: 1px solid #e4e4e4;
}

.campaign-hero_image__J8h8b {
    border: 0.5px solid #c8c8c8;
    border-radius: 0.75rem;
    display: block;
    height: 100%;
    overflow: hidden;
    padding-top: 56.25% !important;
    position: relative;
    width: 100%;
}

@media screen and (max-width: 47.99875em) {
    .campaign-hero_image__J8h8b {
        border-radius: 0.5rem;
    }
}

.campaign-hero_image__J8h8b img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}

.expansion-list-wrapper_header__d8_An {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.expansion-list-wrapper_filter__Uly2O {
    border-radius: 1rem;
}

.campaign-members-team_campaignMembersTeam__LbniQ {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    grid-template-columns: auto;
    grid-template-rows: auto;
}

@media print, screen and (min-width: 64em) {
    .campaign-members-team_campaignMembersTeam__LbniQ {
        grid-column-gap: 2rem;
        grid-template-columns: 1fr 1fr;
    }
    .campaign-members-team_campaignMembersTeam__LbniQ > :first-child {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
    }
    .campaign-members-team_campaignMembersTeam__LbniQ > :nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
    }
    .campaign-members-team_campaignMembersTeam__LbniQ > :nth-child(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
    }
    .campaign-members-team_campaignMembersTeam__LbniQ > :nth-child(4) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
    }
}

.clipboard-field_content__EqsXD {
    max-height: 240px;
    pointer-events: none;
    position: relative;
}

.clipboard-field_label___CbOS {
    background: #fff;
    color: initial;
    left: 1rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    position: absolute;
    top: -0.6rem;
}

@media print, screen and (min-width: 64em) {
    .clipboard-field_label___CbOS {
        font-size: 0.875rem;
    }
}

.clipboard-field_textarea__57Nm_ {
    min-height: 6rem;
    pointer-events: auto;
}

.clipboard-field_icon__yTqK2 {
    font-size: 1.3125rem;
}

.clipboard-field_inlineContent__JW56e {
    flex: 1 1 auto;
    margin-right: 1rem;
}

.clipboard-field_inlineButton__3Ns7D {
    height: 3.125rem;
    min-width: 7rem;
}

.clipboard-field_input__6O_mx {
    pointer-events: auto;
}

.gallery-content_galleryContent__4j8c7 {
    color: #fff;
    position: relative;
}

@media screen and (max-width: 63.99875em) {
    .gallery-content_galleryContent__4j8c7 {
        height: 100%;
    }
}

@media print, screen and (min-width: 64em) {
    .gallery-content_galleryContent__4j8c7 {
        flex-grow: 1;
        height: 0;
        position: relative;
    }
}

.gallery-content_inner___f88a {
    background: #333;
    height: 100%;
}

@media print, screen and (min-width: 64em) {
    .gallery-content_inner___f88a {
        border-radius: 0.5rem;
        display: grid;
        grid-template-columns: 66% 34%;
        grid-template-rows: 100%;
        overflow: hidden;
    }
}

.gallery-content_image__N2KhM {
    align-items: center;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 63.99875em) {
    .gallery-content_image__N2KhM {
        height: 100%;
    }
}

.gallery-content_info__fNkDu {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

@media screen and (max-width: 63.99875em) {
    .gallery-content_info__fNkDu {
        background: linear-gradient(180deg, #0000 0, #000000b3);
        bottom: 3.5rem;
        padding-top: 4rem;
        position: absolute;
        width: 100%;
    }
}

@media print, screen and (min-width: 64em) {
    .gallery-content_info__fNkDu {
        padding: 2rem 2rem 3rem;
        position: relative;
    }
}

@media screen and (max-width: 63.99875em) {
    .gallery-content_isOpen__Lm3mn {
        background: #000000b3;
        padding-top: 3rem;
        top: 3.5rem;
    }
}

.gallery-content_author__DTTqc {
    margin-bottom: 2rem;
}

@media print, screen and (min-width: 64em) {
    .gallery-content_isOpenAuthor__9xo8Y {
        min-height: 2.6875rem;
    }
}

.gallery-content_text__zyW_f {
    overflow-y: auto;
    word-break: break-all;
    word-break: break-word;
    word-wrap: break-word;
}

.gallery-content_index__b_lkR {
    bottom: 1rem;
    position: absolute;
    right: 2rem;
}

@media print, screen and (min-width: 64em) {
    .gallery-content_arrow__nc9y9 {
        padding: 1rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.gallery-content_arrowLeft__ZjUzv {
    left: -4.5rem;
}

.gallery-content_arrowRight__ucehy {
    right: -4.5rem;
}

.gallery-footer_galleryFooter__DcHRg {
    color: #fff;
}

@media screen and (max-width: 63.99875em) {
    .gallery-footer_galleryFooter__DcHRg {
        border-top: 1px solid #767676;
        bottom: 0;
        height: 3.5rem;
        left: 0;
        position: absolute;
        width: 100%;
    }
}

@media print, screen and (min-width: 64em) {
    .gallery-footer_galleryFooter__DcHRg {
        margin-top: 1rem;
    }
}

.gallery-footer_nav__iQcNW {
    align-items: center;
    background: #000000b3;
    bottom: 0;
    display: flex;
    height: 100%;
    padding: 0.5rem 1rem;
}

.gallery-footer_index__tJiab {
    flex-grow: 1;
    text-align: center;
}

.gallery-footer_carousel__rKvLZ {
    overflow: hidden;
    padding-top: 4.375rem;
    position: relative;
    width: 100%;
}

@media screen and (min-width: 72em) {
    .gallery-footer_carousel__rKvLZ {
        padding-top: 5.625rem;
    }
}

.gallery-footer_carouselSlide__UosfF {
    display: flex;
    padding-bottom: 0.5rem;
    position: absolute;
    top: 0;
    transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.gallery-footer_carouselItem__aSFpa {
    border: 1px solid #0000;
    height: 3.375rem;
    margin: 0.25rem;
    position: relative;
    width: 5rem;
}

.gallery-footer_carouselItem__aSFpa:after {
    background: #0006;
    bottom: 0;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
}

.gallery-footer_carouselItem__aSFpa:hover:after {
    display: none;
}

@media screen and (min-width: 72em) {
    .gallery-footer_carouselItem__aSFpa {
        height: 4.625rem;
        width: 6.25rem;
    }
}

.gallery-footer_isActive__BS_Nd {
    border: 1px solid #fff;
}

.gallery-footer_isActive__BS_Nd:after {
    display: none;
}

.gallery-footer_carouselItemButton__TPC3r {
    display: block;
    height: 100%;
    width: 100%;
}

.gallery-footer_carouselArrows__JkeSt {
    margin: 0.25rem;
    text-align: right;
}

.gallery-footer_carouselPlayButton__Elx0S {
    font-size: 0.375rem;
}

.gallery-header_galleryHeader__Usm_6 {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: row-reverse;
    min-height: 3.5rem;
}

@media screen and (max-width: 63.99875em) {
    .gallery-header_galleryHeader__Usm_6 {
        background: #000000b3;
        border-bottom: 1px solid #767676;
        left: 0;
        padding: 0.5rem 1rem;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10;
    }
}

.gallery-header_close__us3sN {
    align-items: center;
    display: flex;
    font-size: 1.3em;
    padding: 0.5rem;
}

.gallery-header_author__8_btu {
    flex-grow: 1;
}

.person-loading_avatar__d4Jjf {
    align-content: center;
    background-color: #c8c8c8;
    border-radius: 50%;
    flex-shrink: 0;
    height: 2.5rem;
    width: 2.5rem;
}

.person-loading_child__sAM2f {
    background-color: #c8c8c8;
    height: 1rem;
}

.person-loading_child__sAM2f:first-child {
    width: 80%;
}

.person-loading_child__sAM2f:last-child {
    margin-top: 0.5rem;
    width: 30%;
}

.person-loading-list_fade__fx0gJ {
    background: linear-gradient(180deg, #fff0 0, #fff);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.person-loading-list_item__URa2o:not(:last-child) {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

.gallery_gallery__gL4fF {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

@media screen and (max-width: 63.99875em) {
    .gallery_gallery__gL4fF {
        background: #333;
    }
}

@media print, screen and (min-width: 64em) {
    .gallery_gallery__gL4fF {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 63.99875em) {
    .gallery_inner__gkrzr {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
    }
}

@media print, screen and (min-width: 64em) {
    .gallery_inner__gkrzr {
        display: flex;
        flex-direction: column;
        height: 100vh;
        max-height: 765px;
        position: relative;
        width: 51.25rem;
    }
}

@media screen and (min-width: 72em) {
    .gallery_inner__gkrzr {
        width: 63.125rem;
    }
}

.campaign-byline_avatarContainer__90zak {
    align-items: center;
    align-self: center;
    color: #c5912c;
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 2px;
}

.campaign-byline_teamAvatar__U0EZK {
    font-size: 8px;
    margin-right: 0.25rem;
}

.campaign-byline_charityCheckmark__4kMtP {
    margin-top: -3px;
}

.competitor-banner_bannerContainer__FuSHi {
    background-color: #e7f0f7;
    border-radius: 0.25rem;
    padding: 1rem;
}

@media print, screen and (min-width: 64em) {
    .competitor-banner_bannerContainer__FuSHi {
        padding: 1.5rem;
    }
}

.competitor-banner_competitorLink__yn8QL {
    background-color: initial;
    text-decoration: underline;
}

.competitor-banner_competitorLink__yn8QL:hover {
    text-decoration: none;
}

.campaign-description_disableUserSelect__xaYaO {
    -webkit-user-select: none;
    user-select: none;
}

@media screen and (max-width: 63.99875em) {
    .campaign-description_campaignDescription__6P_RU {
        margin-bottom: -0.5rem;
    }
}

.campaign-description_header__3tiFF {
    margin-top: 2rem;
}

@media print, screen and (min-width: 64em) {
    .campaign-description_header__3tiFF {
        margin-top: 1.5rem;
    }
}

.campaign-description_content__C1C_5 {
    overflow: hidden;
    overflow-wrap: break-word;
    position: relative;
    word-break: break-word;
}

/* .campaign-description_content__C1C_5:after {
    background: linear-gradient(180deg, #fff0 0, #fff);
    bottom: 0;
    content: "";
    display: block;
    height: 2rem;
    position: absolute;
    width: 100%
} */

@media print, screen and (min-width: 64em) {
    .campaign-description_content__C1C_5:after {
        background: linear-gradient(180deg, #fff0 0, #fff);
    }
}

.campaign-description_content__C1C_5 img {
    margin-left: auto;
    margin-right: auto;
    max-height: 490px;
    max-width: 490px;
    width: auto;
}

@media screen and (max-width: 47.99875em) {
    .campaign-description_content__C1C_5 img {
        max-width: 100%;
    }
}

.campaign-description_content__C1C_5 img:not(:last-child) {
    margin-bottom: 1.5rem;
}

.campaign-description_isOpen__4cQeG {
    max-height: inherit;
}

.campaign-description_isOpen__4cQeG:after {
    display: none;
}

.purple-indicator_iconAvatar__n82QV {
    border-radius: 50%;
    display: inline-block;
    height: 2.5rem;
    min-width: 2.5rem;
    position: relative;
    width: 2.5rem;
}

.purple-indicator_iconAvatar__n82QV .purple-indicator_icon__Jk3eD {
    font-size: 1.25rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.donation_donationList__fa1FT .m-meta-list-item,
.donation_donationList__fa1FT .m-meta-list-item:not(:first-child):before {
    margin-right: 0.5rem;
}

.donation-prompt_prompt__G7F5v {
    background-color: #fbf8f6;
    margin: -0.75rem -1rem;
    padding: 1rem 2rem;
}

@media print, screen and (min-width: 64em) {
    .donation-prompt_prompt__G7F5v {
        margin: -0.75rem -2rem;
    }
}

.donation-list_donationListItem__EoNQQ:not(:last-child) {
    padding-bottom: 0.75rem;
}

.donation-list_donationListItem__EoNQQ:not(:first-child) {
    padding-top: 0.75rem;
}

.top-supporters-list_donationListItem__5zK2d:not(:last-child) {
    padding-bottom: 0.75rem;
}

.top-supporters-list_donationListItem__5zK2d:not(:first-child) {
    padding-top: 0.75rem;
}

.campaign-members-main_campaignMembersMain__mmNDj {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-areas: "organizer" "contact";
    grid-template-columns: auto;
    grid-template-rows: auto;
}

@media print, screen and (min-width: 64em) {
    .campaign-members-main_campaignMembersMain__mmNDj {
        -ms-grid-columns: 1fr 1fr;
        grid-template-areas: "organizer" "contact";
        grid-template-columns: auto auto;
    }
}

.campaign-members-main_hasBeneficiary__Q_wnu {
    grid-template-areas: "organizer" "contact" "beneficiary";
}

@media print, screen and (min-width: 64em) {
    .campaign-members-main_hasBeneficiary__Q_wnu {
        grid-column-gap: 2rem;
        grid-template-areas: "organizer beneficiary" "contact contact";
        grid-template-columns: 1fr 1fr;
    }
}

.campaign-members-main_charityOrganized__qdExo {
    grid-template-areas: "beneficiary" "contact";
}

@media print, screen and (min-width: 64em) {
    .campaign-members-main_charityOrganized__qdExo {
        grid-template-areas: "beneficiary contact";
    }
}

.campaign-members-main_organizer__NRaa5 {
    display: flex;
    grid-area: organizer;
}

.campaign-members-main_beneficiary__pA6vB {
    grid-area: beneficiary;
}

.campaign-members-main_contact__xHCnO {
    grid-area: contact;
}

.campaign-members-main_hasBeneContact__FONnT {
    margin-left: 3.5rem;
    margin-top: 0;
    text-align: left;
}

@media print, screen and (min-width: 64em) {
    .campaign-members-main_charityOrganizedContact__oyJxe {
        margin-bottom: auto;
        margin-top: auto;
        text-align: right;
    }
}

.campaign-members-main_arrow__NO3t3 {
    margin: 0 auto;
}

.campaign-members_campaignMembersHeader__S99vj {
    align-items: flex-start;
}

@media print, screen and (min-width: 64em) {
    .campaign-members_campaignMembersHeader__S99vj {
        align-items: center;
    }
    .campaign-members_campaignMembersHeaderTitle__2iegQ {
        align-items: center;
        display: flex;
    }
    .campaign-sidebar_sidebar__2TEAT {
        background: #fff;
        border-radius: 0.75rem;
        box-shadow: 0 0.3125rem 1rem -0.1875rem #0003;
        padding-bottom: 1.5rem;
    }
    .campaign-sidebar_wrapper__Ay4Wk {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 1.5rem;
    }
}

.campaign-modal_campaignModal__7wLok {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.campaign-modal_wrapper__XHrER {
    display: flex;
    flex-direction: row;
}

.campaign-modal_inner__WQkYx {
    background: #fff;
    box-shadow: 0 0.3125rem 1rem -0.1875rem #00000080;
    display: flex;
    flex-direction: column;
    width: 100vw;
}

@media screen and (max-width: 47.99875em) {
    .campaign-modal_inner__WQkYx {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
    }
}

@media print, screen and (min-width: 48em) {
    .campaign-modal_inner__WQkYx {
        border-radius: 0.25rem;
        max-height: 80vh;
        max-width: 35rem;
    }
}

@media print, screen and (min-width: 48em) and (min-height: 800px) {
    .campaign-modal_inner__WQkYx {
        max-height: 800px;
    }
}

.campaign-modal_header__RTzYQ {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 1rem 1.5rem 0.5rem;
}

@media print, screen and (min-width: 64em) {
    .campaign-modal_header__RTzYQ {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.campaign-modal_subHeader__tFxw4 {
    min-height: auto;
}

.campaign-modal_headerTitleRow___zIp0 {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
}

.campaign-modal_headerTitle__ZKG_K {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.campaign-modal_heading__BVOoR {
    flex: 1 1 auto;
}

.campaign-modal_content__S9Qxt {
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding: 1rem 1.5rem 2rem;
}

@media print, screen and (min-width: 64em) {
    .campaign-modal_content__S9Qxt {
        padding-bottom: 2.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.campaign-modal_footer__gSZYm {
    margin: 1rem 1.5rem;
}

@media print, screen and (min-width: 64em) {
    .campaign-modal_footer__gSZYm {
        margin: 0.5rem 2rem 2rem;
    }
}

.mme-service-banner_mmeServiceBanner__dYRN3 {
    align-items: center;
    background-color: #e7f0f7;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    display: flex;
    grid-area: service-date-banner;
    justify-content: space-between;
    padding: 1rem;
}

@media screen and (max-width: 47.99875em) {
    .mme-service-banner_mmeServiceBanner__dYRN3 {
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 63.99875em) {
    .mme-service-banner_mmeServiceBanner__dYRN3 {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        margin-top: 1rem;
    }
}

@media screen and (min-width: 64em) {
    .mme-service-banner_mmeServiceBanner__dYRN3 {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.mme-service-banner_text__PB6hs {
    color: #333;
    font-size: 0.875rem;
}

.mme-service-banner_text__PB6hs strong {
    font-size: 1rem;
}

.progress-meter_progressMeter__BYOlt {
    display: grid;
    grid-area: progress;
    margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 64em) {
    .progress-meter_progressMeter__BYOlt {
        margin-bottom: 1rem;
    }
}

.progress-meter_progressMeterHeading__A6Slt {
    color: #333;
    line-height: 0.8;
    margin-bottom: 0.75rem;
    margin-top: 0.25rem;
}

@media print, screen and (min-width: 64em) {
    .progress-meter_progressMeterHeading__A6Slt {
        grid-row: 1/2;
        margin-bottom: 0.75rem;
    }
}

.progress-meter_progressBar__JQJ3f {
    margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 64em) {
    .progress-meter_progressBar__JQJ3f {
        grid-row: 2/3;
        margin-bottom: 0.75rem;
    }
}

.progress-meter_largeType__L_4O8 {
    font-size: 1.5rem;
}

.campaign-page-share-icon_fontSize12__T4R76 {
    font-size: 0.75rem;
}

.campaign-page-share-icon_lineHeight16__ddtpG {
    line-height: 1rem;
}

.gen-ai-share-highlight-popover_genAiShareHighlightPopover__mxC8t {
    height: 0;
    margin-top: -1.5rem;
    right: 2rem !important;
}

@media screen and (max-width: 71.99875em) {
    .gen-ai-share-highlight-popover_genAiShareHighlightPopover__mxC8t {
        left: 2.5rem !important;
    }
}

.gen-ai-share-highlight-popover_genAiShareHighlightPopover__mxC8t
    .hrt-popover-bubble {
    bottom: auto !important;
    top: calc(100% + var(--hrt-popover-offset)) !important;
    width: 19.375rem;
}

.gen-ai-share-highlight-popover_genAiShareHighlightPopover__mxC8t
    .hrt-popover-bubble.hrt-popover-bubble--center {
    left: calc(50% - 19.375rem / 2);
}

.gen-ai-share-highlight-popover_genAiShareHighlightPopover__mxC8t
    .hrt-popover-bubble.hrt-popover-bubble--center
    .hrt-bubble--arrow-bottom-center:after {
    left: calc(50% - var(--hrt-bubble-arrow-size) * 0.5) !important;
    top: -0.4375rem !important;
}

.gen-ai-share-highlight-popover_genAiShareHighlightPopover__mxC8t
    .hrt-popover-bubble.hrt-popover-bubble--center
    .hrt-bubble--arrow-bottom-center:after
    .hrt-popover-bubble-close {
    height: 2rem;
    width: 2rem;
}

.gen-ai-share-highlight-popover_genAiShareHighlightPopover__mxC8t
    .hrt-popover-bubble.hrt-popover-bubble--center
    .hrt-bubble--arrow-bottom-center:after
    .hrt-popover-bubble-close
    .hrt-icon.hrt-icon--small {
    height: 1rem;
    width: 1rem;
}

@keyframes share-icon-with-loader_fadeIn__uh5lt {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes share-icon-with-loader_fadeOut__717Fo {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes share-icon-with-loader_fadeInAndUp__OnNeF {
    0% {
        opacity: 0;
        transform: translate3d(0, 1rem, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
}

@keyframes share-icon-with-loader_fadeOutAndUp__MBILR {
    0% {
        opacity: 1;
        transform: translateZ(0);
    }
    to {
        opacity: 0;
        transform: translate3d(0, -16px, 0);
    }
}

@keyframes share-icon-with-loader_bloop__qzhrR {
    0% {
        opacity: 0;
        transform: scale(0.1);
    }
    50% {
        opacity: 1;
        transform: scale(1.1);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes share-icon-with-loader_clockwiseSpin__3d4AO {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(1turn);
    }
}

@keyframes share-icon-with-loader_counterClockwiseSpin__5dKDB {
    0% {
        transform: rotate(1turn);
    }
    to {
        transform: rotate(0deg);
    }
}

@keyframes share-icon-with-loader_snakeChase__oJ7e_ {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
        clip-path: polygon(
            50% 50%,
            0 0,
            100% 0,
            100% 100%,
            100% 100%,
            100% 100%
        );
    }
    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    to {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
}

@keyframes share-icon-with-loader_scaleUp__183LD {
    0% {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@keyframes share-icon-with-loader_scaleDown__7D95q {
    0% {
        transform: scale(1);
    }
    to {
        transform: scale(0);
    }
}

.share-icon-with-loader_iconBoundary__4RH1q {
    align-items: center;
    border: 0.1875rem solid #0000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 0.1875rem;
    position: relative;
    transition: all 0.75s ease;
}

.share-icon-with-loader_iconButton__VmaBk {
    border-radius: 50%;
    justify-content: center;
}

.share-icon-with-loader_iconButton__VmaBk.share-icon-with-loader_xlarge__8mzYU {
    height: 2.5rem;
    width: 2.5rem;
}

.share-icon-with-loader_iconButton__VmaBk.share-icon-with-loader_xxlarge__q6q2_ {
    height: 3.75rem;
    width: 3.75rem;
}

.share-icon-with-loader_iconButton__VmaBk.share-icon-with-loader_gray10__C3LTT {
    background-color: #f1f1f1;
}

.share-icon-with-loader_iconButton__VmaBk.share-icon-with-loader_green__8FGi2 {
    background-color: #02a95c;
}

.share-icon-with-loader_iconButton__VmaBk.share-icon-with-loader_purple__fgk41 {
    background-color: #6d2e91;
}

.share-icon-with-loader_iconButton__VmaBk.share-icon-with-loader_transparent__QqOk_ {
    background-color: initial;
}

.share-icon-with-loader_iconName___SVji {
    color: #767676;
    font-size: 0.875rem;
    margin-bottom: 0.1875rem;
    margin-top: 0.1875rem;
}

.share-icon-with-loader_underline__pwVxp {
    background-color: #6d2e91;
    border-radius: 0.1875rem;
    height: 0.25rem;
    transition: width 0.75s ease;
    width: 0;
}

.share-icon-with-loader_shareIconWithLoader__2gs6D {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 0.75rem;
}

.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_xlarge__8mzYU {
    width: 2.5rem;
}

.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_xxlarge__q6q2_ {
    width: 3.75rem;
}

.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_active__fUzmg
    .share-icon-with-loader_iconBoundary__4RH1q {
    border-color: #6d2e91;
}

.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_active__fUzmg
    .share-icon-with-loader_iconName___SVji {
    color: #333;
    font-weight: 700;
}

.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_active__fUzmg
    .share-icon-with-loader_underline__pwVxp {
    width: 0.9375rem;
}

.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_loading___lI0H
    .share-icon-with-loader_iconBoundary__4RH1q {
    border-color: #0000;
}

.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_loading___lI0H
    .share-icon-with-loader_loader__Pc8Tr {
    animation: share-icon-with-loader_clockwiseSpin__3d4AO 1s linear infinite;
    border-radius: 50%;
    position: absolute;
}

.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_loading___lI0H
    .share-icon-with-loader_loader__Pc8Tr.share-icon-with-loader_xlarge__8mzYU {
    height: 3rem;
    width: 3rem;
}

.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_loading___lI0H
    .share-icon-with-loader_loader__Pc8Tr.share-icon-with-loader_xxlarge__q6q2_ {
    height: 4.5rem;
    width: 4.5rem;
}

.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_loading___lI0H
    .share-icon-with-loader_loader__Pc8Tr:after,
.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_loading___lI0H
    .share-icon-with-loader_loader__Pc8Tr:before {
    animation: share-icon-with-loader_snakeChase__oJ7e_ 2s linear infinite;
    border: 0.1875rem solid;
    border-radius: 50%;
    box-sizing: border-box;
    content: "";
    inset: 0;
    position: absolute;
}

.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_loading___lI0H
    .share-icon-with-loader_loader__Pc8Tr:after {
    transform: rotate3d(90, 90, 0, 270deg);
}

.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_loading___lI0H
    .share-icon-with-loader_loader__Pc8Tr.share-icon-with-loader_green__8FGi2:after,
.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_loading___lI0H
    .share-icon-with-loader_loader__Pc8Tr.share-icon-with-loader_green__8FGi2:before {
    border-color: #02a95c;
}

.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_loading___lI0H
    .share-icon-with-loader_loader__Pc8Tr.share-icon-with-loader_purple__fgk41:after,
.share-icon-with-loader_shareIconWithLoader__2gs6D.share-icon-with-loader_loading___lI0H
    .share-icon-with-loader_loader__Pc8Tr.share-icon-with-loader_purple__fgk41:before {
    border-color: #6d2e91;
}

.copy-confirmation_primaryCtaForFacebook__RfTqG {
    background-color: #1877f2;
    height: 3rem;
    max-width: 14.8125rem;
    padding: 0;
    position: relative;
    width: 100%;
}

.share-icons-exposed-expedited-share-ai-v3-exp_shareIcon__MivOk.share-icons-exposed-expedited-share-ai-v3-exp_grayDark__blIE7 {
    background-color: #333;
    color: #fff;
}

.share-icons-exposed-expedited-share-ai-v3-exp_shareIcon__MivOk.share-icons-exposed-expedited-share-ai-v3-exp_greenDark__roXbm {
    background-color: #02a95c;
    color: #fff;
}

@keyframes skeleton_skeletonPulse__aLKty {
    0% {
        background-color: #f8f8f8;
    }
    50% {
        background-color: #f1f1f1;
    }
    to {
        background-color: #f8f8f8;
    }
}

.skeleton_skeleton__n6CQu {
    animation-duration: 1.1s;
    animation-iteration-count: infinite;
    animation-name: skeleton_skeletonPulse__aLKty;
    animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
    display: block;
}

.share-and-donate_exposedShareIconsWrapper__9YQF7 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.share-and-donate_exposedShareIconsCard__oOHGA {
    border: 1px solid #e4e4e4;
}

.share-and-donate_shareIconsLoadingState__Gwk0V {
    border-radius: 0.75rem;
    height: 3rem;
}

.brand-value-propositions_brandValuePropositions__ZcSfv {
    padding-bottom: 4.5rem;
    padding-top: 4.5rem;
}

@media screen and (min-width: 48em) {
    .brand-value-propositions_brandValuePropositions__ZcSfv {
        padding-bottom: 5.5rem;
        padding-top: 5.5rem;
    }
}

.date-and-category_dateAndCategory__m56MT {
    font-size: 1rem !important;
    padding-bottom: 1rem;
    padding-top: 1rem;
    row-gap: 1rem;
}

.date-and-category_dateAndCategory__m56MT
    .m-meta-list-item:not(:first-child):before {
    color: #c8c8c8;
}

.sticky_stickyCta__dBfjV {
    position: relative;
    z-index: 1;
}

.sticky_inner__AM7EH {
    background-color: #fff;
    box-shadow: 0 2px 6px #0000001a;
    min-height: 5rem;
    opacity: 0;
    padding: 1rem;
    position: fixed;
    transition: top 0.2s ease-in-out, opacity 0.2s ease-in-out;
    width: 100%;
}

.sticky_inner__AM7EH button {
    min-width: 110px;
    -webkit-tap-highlight-color: transparent;
}

@media print, screen and (min-width: 64em) {
    .sticky_inner__AM7EH {
        display: none;
    }
}

.sticky_top__2anFj {
    top: -90px;
}

.sticky_bottom__ma5tm {
    bottom: -80px;
    padding-bottom: env(safe-area-inset-bottom);
    transition: bottom 0.2s ease-in-out, opacity 0.2s ease-in-out,
        background-color 0.5s ease;
}

.sticky_isOpen__bpg5W {
    opacity: 1;
}

.sticky_topOpen__obsk6 {
    top: 0;
}

.sticky_bottomOpen__ty4KA {
    bottom: 0;
    left: 0;
}

.sticky_ctaClipsGray__x0_Mz {
    transition: background-color 0.5s ease;
}

.instagram-share-button_m-instagram-new-tag__TQ9US {
    margin-left: 1rem;
}

.campaign-page_campaign__SFW7x .p-campaign {
    display: grid;
    grid-template-areas: "partner-banner" "service-date-banner" "collage" "header" "progress" "share-and-donate" "status" "byline" "description" "content" "reportbutton" " referrals" "valueprops" "sidebar";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
}

@media screen and (min-width: 64em) {
    .campaign-page_campaign__SFW7x .p-campaign {
        grid-template-areas: "partner-banner partner-banner partner-banner partner-banner" "header header header header" "collage collage collage sidebar" "byline byline . sidebar" "description description . sidebar" "content content . sidebar" "reportbutton reportbutton . sidebar" "referrals referrals . sidebar" "valueprops valueprops valueprops valueprops";
        grid-template-columns: 4fr 4fr 0fr 4.5fr;
        grid-template-rows: auto;
    }
}

@media screen and (min-width: 72em) {
    .campaign-page_campaign__SFW7x .p-campaign {
        grid-template-columns: 4fr 4fr 0.5fr 4.5fr;
    }
}

.campaign-page_campaign__SFW7x .p-campaign .hrt-rule--horizontal {
    margin-bottom: 3rem;
    margin-top: 2.5rem;
}

@media screen and (max-width: 63.99875em) {
    .campaign-page_campaign__SFW7x .p-campaign .hrt-rule--horizontal {
        margin-bottom: 2rem;
        margin-top: 1.75rem;
    }
}

.campaign-page_campaign__SFW7x .p-campaign.clips {
    padding-top: 16px;
}

@media screen and (max-width: 63.99875em) {
    .campaign-page_campaign__SFW7x .p-campaign.clips {
        grid-template-areas: "status" "partner-banner" "service-date-banner" "header" "progress" "collage" "byline" "description" "content" "reportbutton" "referrals" "valueprops";
    }
    .campaign-page_campaign__SFW7x .p-campaign.clips .p-campaign-header {
        margin-bottom: 1rem;
        margin-top: 2rem;
        width: calc(100% - 0.5rem);
    }
    .campaign-page_campaign__SFW7x .p-campaign.clips .a-progress-bar {
        margin-bottom: 0.25rem;
        width: 100%;
    }
    .campaign-page_campaign__SFW7x .p-campaign.clips .p-campaign-byline {
        margin-top: 1.5rem;
    }
    .campaign-page_campaign__SFW7x .p-campaign.clips .clips-scroll-snap {
        background-color: #333;
        border-radius: 1em;
        scroll-margin-bottom: 80px;
        scroll-snap-align: start;
        text-align: center;
    }
    .campaign-page_campaign__SFW7x .p-campaign.clips .clips-no-snap {
        background-color: #333;
        border-radius: 1em;
        text-align: center;
    }
    .campaign-page_campaign__SFW7x
        .p-campaign.clips
        .p-campaign-collage--clips {
        border-radius: 1em;
        margin-left: -1rem;
        margin-right: -1rem;
    }
}

@media screen and (max-width: 63.99875em) and (orientation: landscape) {
    .campaign-page_campaign__SFW7x .p-campaign.clips .clips-scroll-snap {
        scroll-snap-align: none;
    }
}

.campaign-page_campaign__SFW7x .p-campaign-collage--clips {
    background-color: #333;
}

.campaign-page_campaign__SFW7x .p-campaign-header {
    grid-area: header;
    margin-bottom: 0.5rem;
    margin-left: 0;
    margin-top: 1rem;
}

@media print, screen and (min-width: 64em) {
    .campaign-page_campaign__SFW7x .p-campaign-header {
        margin-bottom: 0;
        margin-top: 1rem;
    }
}

.campaign-page_campaign__SFW7x .p-campaign-title {
    font-size: calc(28px + 12 * (100vw - 375px) / 777);
    line-height: 1.2;
    margin-bottom: 0;
}

@media screen and (min-width: 72em) {
    .campaign-page_campaign__SFW7x .p-campaign-title {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 375px) {
    .campaign-page_campaign__SFW7x .p-campaign-title {
        font-size: 1.75rem;
    }
}

.campaign-page_campaign__SFW7x .p-campaign-sidebar {
    grid-area: sidebar;
    margin: 0.5rem 0 0;
}

@media print, screen and (min-width: 64em) {
    .campaign-page_campaign__SFW7x .p-campaign-sidebar {
        margin-left: 1.5rem;
        margin-top: 1.5rem;
        position: relative;
    }
    .campaign-page_campaign__SFW7x .p-campaign-sidebar .o-campaign-sidebar {
        position: sticky;
        top: 1rem;
    }
}

.campaign-page_campaign__SFW7x .p-campaign-collage {
    grid-area: collage;
    margin-top: 1.5rem;
    border-radius: 0.5rem;
}

@media screen and (max-width: 47.99875em) {
    .campaign-page_campaign__SFW7x .p-campaign-collage {
        margin-top: 0;
    }
}

@media screen and (min-width: 48em) {
    .campaign-page_campaign__SFW7x .p-campaign-collage {
        border-radius: 0.75rem;
    }
}

@media screen and (max-width: 63.99875em) {
    .campaign-page_campaign__SFW7x .p-campaign-collage {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
    }
}

.campaign-page_campaign__SFW7x .p-campaign-collage--video {
    background-color: initial !important;
    height: 14em;
}

@media print, screen and (min-width: 48.0625rem) {
    .campaign-page_campaign__SFW7x .p-campaign-collage--video {
        height: 20.8em;
    }
}

@media print, screen and (min-width: 71.875rem) {
    .campaign-page_campaign__SFW7x .p-campaign-collage--video {
        height: 25.66em;
    }
}

.campaign-page_campaign__SFW7x .p-campaign-byline {
    grid-area: byline;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    padding: 0 0.5rem;
}

@media screen and (max-width: 47.99875em) {
    .campaign-page_campaign__SFW7x .p-campaign-byline {
        padding: 0;
    }
}

@media print, screen and (min-width: 64em) {
    .campaign-page_campaign__SFW7x .p-campaign-byline {
        margin-bottom: 1rem;
        margin-top: 1rem;
        max-width: 664px;
        position: relative;
    }
}

.campaign-page_campaign__SFW7x .p-campaign-description {
    grid-area: description;
    padding: 0 0.5rem 2rem;
}

@media screen and (max-width: 47.99875em) {
    .campaign-page_campaign__SFW7x .p-campaign-description {
        padding: 0;
    }
}

@media print, screen and (min-width: 64em) {
    .campaign-page_campaign__SFW7x .p-campaign-description {
        max-width: 664px;
        position: relative;
    }
}

.campaign-page_campaign__SFW7x .p-campaign-content {
    grid-area: content;
    margin-top: 1rem;
}

@media screen and (max-width: 47.99875em) {
    .campaign-page_campaign__SFW7x .p-campaign-content {
        margin-top: 0.25rem;
    }
}

@media print, screen and (min-width: 64em) {
    .campaign-page_campaign__SFW7x .p-campaign-content {
        max-width: 664px;
        padding: 0 0.5rem;
    }
}

.campaign-page_campaign__SFW7x .p-campaign-content--mobile-buttons {
    margin-top: 1.75rem;
}

.campaign-page_campaign__SFW7x .p-campaign-brand-value-proposition,
.campaign-page_campaign__SFW7x .p-campaign-comments,
.campaign-page_campaign__SFW7x .p-campaign-donations,
.campaign-page_campaign__SFW7x .p-campaign-members,
.campaign-page_campaign__SFW7x .p-campaign-updates {
    grid-area: valueprops;
    margin-bottom: 2.5rem;
    margin-top: 3rem;
}

@media print, screen and (min-width: 64em) {
    .campaign-page_campaign__SFW7x .p-campaign-brand-value-proposition,
    .campaign-page_campaign__SFW7x .p-campaign-comments,
    .campaign-page_campaign__SFW7x .p-campaign-donations,
    .campaign-page_campaign__SFW7x .p-campaign-members,
    .campaign-page_campaign__SFW7x .p-campaign-updates {
        padding: 0 0.5rem;
    }
}

@media screen and (max-width: 47.99875em) {
    .campaign-page_campaign__SFW7x .p-campaign-brand-value-proposition,
    .campaign-page_campaign__SFW7x .p-campaign-comments,
    .campaign-page_campaign__SFW7x .p-campaign-donations,
    .campaign-page_campaign__SFW7x .p-campaign-members,
    .campaign-page_campaign__SFW7x .p-campaign-updates {
        margin-bottom: 2rem;
        margin-top: 1.75rem;
    }
}

.campaign-page_campaign__SFW7x .p-campaign-donations h2,
.campaign-page_campaign__SFW7x .p-campaign-members h2,
.campaign-page_campaign__SFW7x .p-campaign-updates h2 {
    margin-bottom: 1.5rem;
}

@media print, screen and (min-width: 64em) {
    .campaign-page_campaign__SFW7x .p-campaign-donations h2,
    .campaign-page_campaign__SFW7x .p-campaign-members h2,
    .campaign-page_campaign__SFW7x .p-campaign-updates h2 {
        margin-bottom: 2rem;
    }
}

.campaign-page_campaign__SFW7x .p-campaign-updates {
    margin-top: 0 !important;
}

.campaign-page_campaign__SFW7x .p-campaign-report-button {
    align-items: center;
    display: flex;
    grid-area: reportbutton;
    margin-bottom: 3rem;
    margin-top: 3rem;
}

@media screen and (max-width: 47.99875em) {
    .campaign-page_campaign__SFW7x .p-campaign-report-button {
        justify-content: center;
    }
}

.campaign-page_campaign__SFW7x .exp-gradient,
.campaign-page_campaign__SFW7x .exp-gradient-gfm {
    position: relative;
}

.campaign-page_campaign__SFW7x .exp-gradient .icon-align-left,
.campaign-page_campaign__SFW7x .exp-gradient-gfm .icon-align-left {
    position: absolute;
}

.campaign-page_campaign__SFW7x .exp-gradient-gfm .icon-align-left {
    left: 0.5rem;
    top: 0.5rem;
}

.campaign-page_campaign__SFW7x .exp-gradient .icon-align-left {
    left: 0.625rem;
    top: 0.625rem;
}

.campaign-page_campaign__SFW7x .exp-gradient-gfm--donate,
.campaign-page_campaign__SFW7x .exp-gradient-gfm--donate:hover {
    background: #f58131;
    background: linear-gradient(180deg, #fdb933 35.42%, #f58131 139.58%);
    border-color: #c06828;
    font-size: 1rem;
}

.campaign-page_campaign__SFW7x .p-campaign-donation-suggestion-well {
    text-decoration: none;
}

.campaign-page_campaign__SFW7x .p-campaign-donation-suggestion-well-content {
    margin-left: -1rem;
    margin-right: -1rem;
    position: relative;
}

@media print, screen and (min-width: 64em) {
    .campaign-page_campaign__SFW7x
        .p-campaign-donation-suggestion-well-content {
        margin-left: 0;
        margin-right: 0;
    }
}

.campaign-page_campaign__SFW7x .p-campaign-donation-suggestion-well-icon-large {
    bottom: 0;
    position: absolute;
}

.campaign-page_campaign__SFW7x
    .p-campaign-donation-suggestion-well-image-placeholder {
    height: 3.125rem;
    width: 8.75rem;
}

.campaign-page_campaign__SFW7x
    .p-campaign-donation-suggestion-well-small-image-container {
    align-self: center;
    flex-basis: 5rem;
    flex-grow: 0;
    flex-shrink: 0;
}

.campaign-page_campaign__SFW7x
    .p-campaign-donation-suggestion-well-text-container {
    background-color: #f0eaf4;
}

@media (orientation: landscape) {
    .campaign-page_campaign__SFW7x .t-campaign-page-header {
        scroll-snap-align: none;
    }
}

.campaign-page_valueProposition__u_sxv {
    margin-top: 3rem;
}

@media screen and (max-width: 47.99875em) {
    .campaign-page_valueProposition__u_sxv {
        margin-top: 1.5rem;
    }
}

.campaign-page_referrals__tE6NK {
    align-items: center;
    display: flex;
    grid-area: referrals;
    height: 100%;
    padding-bottom: 3rem;
    padding-top: 3rem;
}

@media screen and (max-width: 47.99875em) {
    .campaign-page_referrals__tE6NK {
        justify-content: center;
        margin-left: -1rem;
        margin-right: -1rem;
    }
}

.campaign-page_collections__ZmRpO {
    padding: 4rem 1rem;
}

@media screen and (min-width: 48em) {
    .campaign-page_collections__ZmRpO {
        margin-top: 3rem;
        padding: 7.5rem 7.5rem 6rem;
    }
}

.campaign-page_shareAndDonate__qRlrx {
    grid-area: share-and-donate;
}

/*# sourceMappingURL=9cca3e47b0ec741f.css.map*/
